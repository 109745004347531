import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  add: true,
  id: {
    value: '',
    showUiError: false,
  },
  maxPoints: {
    value: '',
    showUiError: false,
  },
  isJackpotWon: {
    value: '',
    showUiError: false,
  },
  jackpotValue: {
    value: '',
    showUiError: false,
  },
  newWheelConfiguration: {
    value: [],
    showUiError: false,
  },
  newValues: {
    value: [],
    showUiError: false,
  },
  newValuesProbabilities: {
    value: [],
    showUiError: false,
  },
};

const addOrEditInAppGamesItems = createSlice({
  name: 'addOrEditInAppGamesItems',
  initialState,
  reducers: {
    clearState: state => {
      state.add = true;
      state.id = {
        value: '',
        showUiError: false,
      };
      state.maxPoints = {
        value: '',
        showUiError: false,
      };
      state.isJackpotWon = {
        value: '',
        showUiError: false,
      };
      state.jackpotValue = {
        value: '',
        showUiError: false,
      };
      state.newWheelConfiguration = {
        value: [],
        showUiError: false,
      };
      state.newValues = {
        value: [],
        showUiError: false,
      };
      state.newValuesProbabilities = {
        value: [],
        showUiError: false,
      };
    },
    prepareStateForEdit: (state, { payload }) => {
      state.add = false;
      state.id = {
        value: payload.id,
        showUiError: false,
      };
      state.maxPoints = {
        value: payload.maxPoints,
        showUiError: false,
      };
      state.isJackpotWon = {
        value: payload.isJackpotWon,
        showUiError: false,
      };
      state.newWheelConfiguration = {
        value: payload.newWheelConfiguration,
        showUiError: false,
      };
      state.jackpotValue = {
        value: payload.jackpotValue,
        showUiError: false,
      };
      state.newValues = {
        value: payload.newValues,
        showUiError: false,
      };
      state.newValuesProbabilities = {
        value: payload.newValuesProbabilities,
        showUiError: false,
      };
    },

    updateState: (state, { payload }) => {
      state[payload.name].value = payload.value;
    },
    updateValuesArray: (state, { payload }) => {
      state[payload.arrayName].value[payload.index] = payload.value;
    },
  },
});

export default addOrEditInAppGamesItems.reducer;
export const {
  clearState,
  updateState,
  prepareStateForEdit,
  updateValuesArray,
} = addOrEditInAppGamesItems.actions;

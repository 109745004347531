import { db } from './firebase-users-config';

import {
  Timestamp,
  collection,
  getDocs,
  query,
  updateDoc,
  doc,
  arrayRemove,
  setDoc,
  deleteDoc,
  getDoc,
  where,
} from 'firebase/firestore';

const ordersCollectionRef = collection(db, 'orders');

class OrdersDataService {
  getAllOrders = () => {
    return getDocs(query(ordersCollectionRef, where('orders', '!=', null)));
  };

  updateOrderStatus = (element, newOrdeStatus) => {
    const userId = element.id;
    const orderId = element.orderId;

    let newOrderItem = {
      ...element,
      orderStatus: newOrdeStatus,
      dateOfLastStatusChange: Timestamp.fromDate(new Date()),
      dateOfOrderPlacement: Timestamp.fromMillis(element.dateOfOrderPlacement),
    };
    delete newOrderItem['email'];
    delete newOrderItem['id'];
    delete newOrderItem['orderId'];
    console.log(newOrderItem);
    const userOrdersRef = doc(db, 'orders', userId);
    return setDoc(
      userOrdersRef,
      {
        orders: { [orderId]: newOrderItem },
      },
      { merge: true }
    );
  };

  deleteOrder = async (userMail, userId, productId) => {
    const docRef = doc(db, 'orders', userId);
    const docSnap = await getDoc(docRef);
    console.log(docSnap.data());
    let ayxOrders = docSnap.data().orders;
    delete ayxOrders[productId];
    console.log(ayxOrders);
    return setDoc(docRef, { email: userMail, orders: ayxOrders });
  };
}

export default new OrdersDataService();

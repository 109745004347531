import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const AddOrEditSchema = Yup.object().shape({
  id: Yup.string().required('Id-ul este necesar'),
  category: Yup.string().required('Categoria este necesara'),
  name: Yup.string().required('Numele cazinoului este necesar'),
  gamePriority: Yup.number()
    .typeError('Prioritatea trebuie sa fie un numar')
    .min(0, 'Prioritatea trebuie sa fie pozitiva')
    .lessThan(10000, 'Prioritatea trebuie sa fie mai mica decat 20')
    .required('Prioritatea este necesara'),
  imageURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('Logoul cazinoului cazinoului este necesar'),
  iframeURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul bonusului este necesar'),
  webURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul review-ului este necesar'),
  PGCategory: Yup.string(),
});

export default AddOrEditSchema;

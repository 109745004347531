import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../../services/firebase-admin';
import { useSelector } from 'react-redux';

function ProtectedRoute({ children }) {
  const isLoggedIn = useSelector(state => state.checkCurrentUser.isLoggedIn);
  let authToken = sessionStorage.getItem('Auth Token');
  console.log(authToken);
  console.log(auth());
  if (isLoggedIn) return children;

  return <Navigate to="/" />;
}

export default ProtectedRoute;

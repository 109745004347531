import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const AddOrEditSchema = Yup.object().shape({
  id: Yup.string().required('Id-ul este necesar'),
  name: Yup.string().required('Numele cazinoului este necesar'),
  primaryBonus: Yup.string().required('Bonusul primar este necesar'),
  secondaryBonus: Yup.string(),
  disclaimer: Yup.string(),
  welcomeBonusHeader: Yup.string(),
  logoURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('Logoul cazinoului cazinoului este necesar'),
  bonusURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul bonusului este necesar'),
  reviewURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul review-ului este necesar'),
  rating: Yup.number()
    .typeError('Ratingul trebuie sa fie un numar')
    .min(0, 'Ratingul trebuie sa fie mai mare decat 0')
    .max(6, 'Ratingul trebuie sa fie mai mic sau egal cu 6')
    .required('Ratingul este necesar'),
});

export default AddOrEditSchema;

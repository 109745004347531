import React from 'react';
import { Timestamp } from 'firebase/firestore';
import {
  Heading,
  Container,
  Box,
  HStack,
  Spacer,
  SimpleGrid,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Text,
  VStack,
  Center,
  Tooltip,
  Tag,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { useSelector, useDispatch } from 'react-redux';
import InAppGamesDataService from '../../services/in-app-games-services';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { ImSpinner } from 'react-icons/im';
import { GiAbstract039 } from 'react-icons/gi';
import {
  prepareStateForEdit,
  updateState,
  clearState,
} from '../../features/inAppGamesItems/addOrEditInAppGamesItems';

function RoataNorocului(props) {
  const dispatch = useDispatch();

  const [fortuneWheelStats, setFortuneWheelStats] = useState({});

  const editButtonClicked = e => {
    dispatch(
      prepareStateForEdit({
        id: fortuneWheelStats.id,
        maxPoints: fortuneWheelStats.maxPoints,
        jackpotValue: fortuneWheelStats.jackpotValue,
        isJackpotWon: fortuneWheelStats.isJackpotWon,
        newValues: fortuneWheelStats.newValues,
        newValuesProbabilities: fortuneWheelStats.newValuesProbabilities,
        newWheelConfiguration: fortuneWheelStats.newWheelConfiguration,
      })
    );
  };

  const getFortuneWheelStats = async () => {
    console.log('Getting items...');
    try {
      const data = await InAppGamesDataService.getAllInAppGames();
      let tmp = {};
      for (let doc of data.docs) {
        if (doc.id === 'fortuneWheel') {
          tmp = { ...doc.data(), id: doc.id };
          tmp.values = tmp.newValues.join(' ');
          tmp = {
            ...tmp,
            lastAddedPointsTimestamp: tmp.lastAddedPointsTimestamp.toMillis(),
          };
        }
      }
      setFortuneWheelStats(tmp);
      console.log(tmp);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log('in unse effect');
    getFortuneWheelStats();
  }, []);

  /// UI
  const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
    <Box p="1">
      <span ref={ref} {...rest}>
        {children}
      </span>
    </Box>
  ));

  return (
    <Container mt="3rem" minWidth="95%">
      <Heading size="xl">Jocuri Din Aplicatie</Heading>
      <HStack mt="3">
        <Link to="/admin-dashboard">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Dashboard
          </Button>
        </Link>
        <Spacer />
      </HStack>

      <Box
        mt="10"
        borderWidth="1px"
        borderRadius="sm"
        // boxShadow="sm"
        p="1rem"
      >
        <VStack tspacing={4} align="stretch">
          <HStack>
            <Heading size="lg">Roata Norocului</Heading>
            <Spacer />
            <Box>
              <HStack>
                <Heading size="sm">Statistici Generale</Heading>
                <Tooltip label="Numarul total de rotiri la roata">
                  <CustomCard>
                    <ImSpinner size={28} />
                  </CustomCard>
                </Tooltip>
                <Heading size="sm">{fortuneWheelStats.totalSpins}</Heading>
                <Tooltip label="Numarul de puncte castigate in total la roata">
                  <CustomCard>
                    <GiAbstract039 size={28} />
                  </CustomCard>
                </Tooltip>
                <Heading size="sm">{fortuneWheelStats.totalPointsWon}</Heading>
                <Box width={4}></Box>
              </HStack>
            </Box>
            <Box>
              <HStack>
                <Heading size="sm">Statistici Sesiune</Heading>
                <Tooltip label="Numarul total de rotiri la roata de cand a fost resetata">
                  <CustomCard>
                    <ImSpinner size={28} />
                  </CustomCard>
                </Tooltip>
                <Heading size="sm">
                  {fortuneWheelStats.totalSpinsThisSession}
                </Heading>
                <Tooltip label="Numarul de puncte castigate la roata de cand a fost resetata">
                  <CustomCard>
                    <GiAbstract039 size={28} />
                  </CustomCard>
                </Tooltip>
                <Heading size="sm">
                  {fortuneWheelStats.totalPointsWonThisSession}
                </Heading>
                <Box width={4}></Box>
              </HStack>
            </Box>
          </HStack>

          <TableContainer>
            <Table size="md" variant="unstyled">
              <Tbody>
                <Tr>
                  <Td>
                    <Heading size="sm">Puncte ramase</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    <Heading size="sm">{fortuneWheelStats.maxPoints}</Heading>
                  </Td>
                </Tr>

                <Tr>
                  <Td>
                    <Heading size="sm">Valoare jackpot</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    <Heading size="sm">
                      {fortuneWheelStats.jackpotValue}
                    </Heading>
                  </Td>
                </Tr>

                <Tr>
                  <Td>
                    <Text fontSize="sm">Status Jackpot</Text>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    <Text fontSize="sm">
                      {fortuneWheelStats.isJackpotWon
                        ? 'Câștigat'
                        : 'Necâștigat'}
                    </Text>
                  </Td>
                </Tr>

                <Tr>
                  <Td>
                    <Text fontSize="sm">Configuratie Roata</Text>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    <Text fontSize="sm">
                      {fortuneWheelStats.newWheelConfiguration !== undefined &&
                        fortuneWheelStats.newWheelConfiguration.join(' ')}
                    </Text>
                  </Td>
                </Tr>

                <Tr>
                  <Td>
                    <Text fontSize="sm">Probabilitati Roata</Text>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    {fortuneWheelStats.newValuesProbabilities !== undefined &&
                      fortuneWheelStats.newValuesProbabilities.map(
                        (element, index) => (
                          <Text fontSize="sm" textAlign={'left'}>
                            {fortuneWheelStats.newValues[index]} - {element}%{' '}
                          </Text>
                        )
                      )}
                  </Td>
                </Tr>

                <Tr>
                  <Td>
                    <Text fontSize="sm">Ultima actualizare</Text>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    <Text fontSize="sm">
                      {new Date(
                        fortuneWheelStats.lastAddedPointsTimestamp
                      ).toLocaleString('ro-RO')}
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Center>
            <Box height={20}></Box>
            <Link to="/edit-roata-norocului">
              <Button
                size="md"
                colorScheme="teal"
                variant="solid"
                id={'fortune-wheel'}
                onClick={e => editButtonClicked(e)}
              >
                Editează valorile
              </Button>
            </Link>
          </Center>
        </VStack>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Container>
  );
}

export default RoataNorocului;

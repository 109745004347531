import React, { useEffect, useState } from 'react';
import PromoCarouselDataService from '../../services/promo-carousel-services';
import { removeItemFromPromoCarousel } from '../../features/promoItems/rememberPromoCarouselItemsSlice';
import {
  VStack,
  Heading,
  Container,
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  HStack,
  Spacer,
  SimpleGrid,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import DeleteModal from '../../components/Modal/Modal';
import { ToastContainer, toast } from 'react-toastify';
import {
  clearState,
  prepareStateForEdit,
} from '../../features/promoItems/addPromoItemSlice';
import { IconContext } from 'react-icons';
import { DiAndroid, DiApple } from 'react-icons/di';
import { useSelector, useDispatch } from 'react-redux';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { setPromoCarouselItems } from '../../features/promoItems/rememberPromoCarouselItemsSlice';

function PromoItems(props) {
  const dispatch = useDispatch();

  const promoItems = useSelector(
    state => state.rememberPromoCarouselItems.promoCarouselItems
  );

  const getPromoItems = async () => {
    console.log('Getting items...');
    try {
      const data = await PromoCarouselDataService.getAllPromoCarousels();
      let tmp = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      dispatch(setPromoCarouselItems({ promoCarouselItems: tmp }));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (promoItems.length === 0) {
      getPromoItems();
    } else {
      console.log('Restoring from store');
    }
  }, []);

  const deleteButtonClicked = async id => {
    try {
      await PromoCarouselDataService.deletePromoCarousel(id);
      dispatch(removeItemFromPromoCarousel({ id: id }));
      toast.success('Operatiunea s-a realizat cu succes!');
    } catch (error) {
      toast.error('S-a produs o eroare. Incearca din nou.');
    }
  };

  const editButtonClicked = e => {
    console.log(promoItems[e.target.id]);

    dispatch(prepareStateForEdit(promoItems[e.target.id]));
  };

  return (
    <Container mt="3rem" minWidth="95%">
      <Heading size="xl">Promo Carousel Dashboard</Heading>

      <HStack mt="3">
        <Link to="/admin-dashboard">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Dashboard
          </Button>
        </Link>
        <Spacer />
        <Link to="/add-promo-item">
          <Button
            onClick={() => {
              dispatch(clearState());
              console.log('clicked');
            }}
            colorScheme={'green'}
          >
            Adauga Promoție
          </Button>
        </Link>
      </HStack>

      <Accordion mt="8" allowToggle>
        {promoItems.map((doc, index) => {
          return (
            <AccordionItem key={index}>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <HStack>
                    <IconContext.Provider
                      value={{
                        color: doc.isPublished ? 'green' : 'black',
                      }}
                    >
                      <DiAndroid size={'20'} />
                    </IconContext.Provider>
                    <IconContext.Provider
                      value={{
                        color: doc.isIOSPublished ? 'green' : 'black',
                      }}
                    >
                      <DiApple size={'20'} />
                    </IconContext.Provider>
                    <Box ml={'20px'}></Box>
                    <Heading size="sm">{doc.name}</Heading>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box
                  key={doc.id}
                  borderWidth="1px"
                  borderRadius="sm"
                  // boxShadow="sm"
                  p="1rem"
                >
                  <VStack tspacing={4} align="stretch">
                    <HStack as={'span'}>
                      <Link to="/edit-promo-item">
                        <Button
                          size="sm"
                          id={index}
                          colorScheme="teal"
                          variant="solid"
                          onClick={e => editButtonClicked(e)}
                        >
                          Editeaza
                        </Button>
                      </Link>
                      <Spacer />
                      <DeleteModal
                        id={doc.id}
                        deleteFunction={deleteButtonClicked}
                      />
                    </HStack>
                    <TableContainer>
                      <Table size="md" variant="unstyled">
                        <Tbody>
                          <Tr>
                            <Td>
                              <Heading size="sm">Nume:</Heading>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Heading size="sm">{doc.name}</Heading>
                            </Td>
                          </Tr>

                          {doc.titlesArray.map((title, index) => {
                            return (
                              <>
                                <Tr>
                                  <Td>
                                    <Text fontSize="sm">
                                      Titlu {index + 1}:
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text
                                      sx={{ whiteSpace: 'normal' }}
                                      fontSize="sm"
                                    >
                                      {title}
                                    </Text>
                                  </Td>
                                </Tr>
                                <Tr>
                                  <Td>
                                    {' '}
                                    <Text fontSize="sm">Text {index + 1}:</Text>
                                  </Td>
                                  <Td sx={{ whiteSpace: 'normal' }}>
                                    <Text fontSize="sm">
                                      {doc.textsArray[index]}
                                    </Text>
                                  </Td>
                                </Tr>
                              </>
                            );
                          })}
                          <Tr>
                            <Td>
                              <Text fontSize="sm">Image URL:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.imageURL}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">Web URL:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.webURL}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">Prioritate:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.priority}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">Text Buton:</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.buttonText}</Text>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </VStack>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Container>
  );
}

export default PromoItems;

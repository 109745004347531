import { db } from './firebase-users-config';
import axios from 'axios';
import {
  collection,
  getDocs,
  query,
  doc,
  updateDoc,
  Timestamp,
} from 'firebase/firestore';

class InAppGamesDataService {
  getAllInAppGames = () => {
    const inAppGamesCollectionRef = collection(db, 'gamesV2');

    return getDocs(query(inAppGamesCollectionRef));
  };

  updateFortuneWheelTimestamp = newTimestamp => {
    return axios.post(
      'http://84.234.98.100:2750/updateFortuneWheelTimestamp',
      {
        lastAddedPointsTimestamp: newTimestamp,
      },
      { withCredentials: false }
    );
  };

  editFortuneWheelValues = newFortuneWheelStats => {
    return axios.post(
      'http://84.234.98.100:2750/editFortuneWheelValues',
      {
        newFortuneWheelStats: newFortuneWheelStats,
      },
      { withCredentials: false }
    );
  };
}

export default new InAppGamesDataService();

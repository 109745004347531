import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  add: true,
  index: -1,
  id: {
    value: '',
    showUiError: false,
  },
  bonusURL: {
    value: '',
    showUiError: false,
  },
  disclaimer: {
    value: '',
    showUiError: false,
  },
  logoURL: {
    value: '',
    showUiError: false,
  },
  name: {
    value: '',
    showUiError: false,
  },
  primaryBonus: {
    value: '',
    showUiError: false,
  },
  rating: {
    value: '',
    showUiError: false,
  },
  reviewURL: {
    value: '',
    showUiError: false,
  },
  secondaryBonus: {
    value: '',
    showUiError: false,
  },
  welcomeBonusHeader: {
    value: '',
    showUiError: false,
  },
};

const addOrEditCasinoItemSlice = createSlice({
  name: 'addOrEditCasinoItems',
  initialState,
  reducers: {
    clearState: state => {
      state.add = true;
      state.index = -1;
      state.id = {
        value: '',
        showUiError: false,
      };
      state.bonusURL = {
        value: '',
        showUiError: false,
      };
      state.disclaimer = {
        value: '',
        showUiError: false,
      };
      state.logoURL = {
        value: '',
        showUiError: false,
      };
      state.name = {
        value: '',
        showUiError: false,
      };
      state.primaryBonus = {
        value: '',
        showUiError: false,
      };
      state.rating = {
        value: '',
        showUiError: false,
      };
      state.reviewURL = {
        value: '',
        showUiError: false,
      };
      state.secondaryBonus = {
        value: '',
        showUiError: false,
      };
      state.welcomeBonusHeader = {
        value: '',
        showUiError: false,
      };
    },
    prepareStateForEdit: (state, { payload }) => {
      state.add = false;
      state.index = payload.index;
      state.id = {
        value: payload.id,
        showUiError: false,
      };
      state.bonusURL = {
        value: payload.bonusURL,
        showUiError: false,
      };
      state.disclaimer = {
        value: payload.disclaimer,
        showUiError: false,
      };
      state.logoURL = {
        value: payload.logoURL,
        showUiError: false,
      };
      state.name = {
        value: payload.name,
        showUiError: false,
      };
      state.primaryBonus = {
        value: payload.primaryBonus,
        showUiError: false,
      };
      state.rating = {
        value: payload.rating,
        showUiError: false,
      };
      state.reviewURL = {
        value: payload.reviewURL,
        showUiError: false,
      };
      state.secondaryBonus = {
        value: payload.secondaryBonus,
        showUiError: false,
      };
      state.welcomeBonusHeader = {
        value: payload.welcomeBonusHeader,
        showUiError: false,
      };
    },
    updateState: (state, { payload }) => {
      state[payload.name].value = payload.value;
    },
  },
});

export default addOrEditCasinoItemSlice.reducer;
export const { clearState, updateState, prepareStateForEdit } =
  addOrEditCasinoItemSlice.actions;

import React from 'react';
import { Formik, Form, Field } from 'formik';

import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Select,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react';

function TombolaArrayForm(props) {
  const hasIndex = (array, index) => {
    return array !== undefined && index >= 0 && index < array.length;
  };

  return (
    <FormControl mb={8} variant="floating" isRequired>
      <Field
        as={props.as}
        name={props.name}
        value={props.value}
        onChange={e => {
          console.log('On change function', e.target.name, e.target.value);
          console.log(props.errors);
          console.log(props.touched);
          console.log(hasIndex(props.errors.prizes, props.index));
          let newPrizes = props.values.prizes;
          newPrizes[props.index][e.target.name] = e.target.value;
          props.setFieldValue('prizes', newPrizes);
        }}
        placeholder=" "
      />
      <FormLabel>{props.label}</FormLabel>
    </FormControl>
  );
}
export default TombolaArrayForm;

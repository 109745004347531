import { db } from './firebase-users-config';

import { collection, getDocs, query, doc, setDoc } from 'firebase/firestore';

const gamesCollectionRef = collection(db, 'gameCategoriesV2');

class GamesDataService {
  getAllGames = () => {
    return getDocs(query(gamesCollectionRef));
  };

  addGameItem = (newGame, category, id) => {
    console.log(newGame);
    const docRef = doc(db, 'gameCategoriesV2', category);
    return setDoc(docRef, { games: { [id]: newGame } }, { merge: true });
  };

  editGameItem = (newGame, category, id) => {
    console.log(newGame);
    const docRef = doc(db, 'gameCategoriesV2', category);
    return setDoc(docRef, { games: { [id]: newGame } }, { merge: true });
  };

  removeGameItem = (category, id, games, isVisible, priority, icon, title) => {
    console.log(isVisible, priority, icon, title);
    const docRef = doc(db, 'gameCategoriesV2', category);
    let auxGames = { ...games };
    delete auxGames[id];
    return setDoc(docRef, {
      games: auxGames,
      isVisible: isVisible,
      priority: priority,
      icon: icon,
      title: title,
    });
  };

  editGameCategory = (category, newCategoryMeta) => {
    console.log(newCategoryMeta);
    const docRef = doc(db, 'gameCategoriesV2', category);
    return setDoc(
      docRef,
      {
        icon: newCategoryMeta.icon,
        title: newCategoryMeta.title,
        priority: newCategoryMeta.priority,
        isVisible: newCategoryMeta.isVisible,
      },
      { merge: true }
    );
  };
}

export default new GamesDataService();

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  noDeposit: {
    id: 'noDeposit',
    cazinos: [],
    isIOSPublished: true,
    isAndroidPublished: true,
  },
  topCazinos: {
    id: 'topCazinos',
    cazinos: [],
    isIOSPublished: true,
    isAndroidPublished: true,
  },
};

const rememberCasinoItemSlice = createSlice({
  name: 'CasinoItems',
  initialState,
  reducers: {
    setCazinoItems: (state, { payload }) => {
      state[payload.id].cazinos = payload.cazinos;
      state[payload.id].isIOSPublished = payload.isIOSPublished;
      state[payload.id].isAndroidPublished = payload.isAndroidPublished;
    },
    addCasinoItem: (state, { payload }) => {
      state[payload.id].cazinos.push(payload.cazino);
    },
    removeItemFromCazino: (state, { payload }) => {
      console.log(payload.id, parseInt(payload.index));
      state[payload.id].cazinos.splice(parseInt(payload.index), 1);
    },
    editCazinoItem: (state, { payload }) => {
      for (let cazinoElement in state[payload.id].cazinos[payload.index]) {
        state[payload.id].cazinos[payload.index][cazinoElement] =
          payload['cazino'][cazinoElement];
      }
    },
  },
});

export default rememberCasinoItemSlice.reducer;
export const {
  setCazinoItems,
  addCasinoItem,
  removeItemFromCazino,
  editCazinoItem,
} = rememberCasinoItemSlice.actions;

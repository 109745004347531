import React, { useEffect, useState } from 'react';
import addOrEditMissionItemsSlice, {
  clearState,
} from '../../features/missionItems/addOrEditMissionItemsSlice';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Select,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
  HStack,
  Text,
} from '@chakra-ui/react';

import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import MissionValidationSchema from './MissionValidationSchema';
import FormField from '../../components/FormField/FormField';
import { RiCloseCircleFill } from 'react-icons/ri';
import ArrayFormField from '../../components/ArrayFormField/ArrayFormField';
import { editMission } from '../../features/missionItems/rememberMissionItemsSlice';
import MissionsDataService from '../../services/mission-services';
import ArrayFormFiledSuggestions from '../../components/ArrayFormFiledSuggestions/ArrayFormFiledSuggestions';
import GamesDataService from '../../services/games-servicesV2';
import games_array from '../../services/gamesArray';

function AddOrEditMission() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const missionDetails = useSelector(state => state.addOrEditMissionItems);
  const [isLoading, setIsLoading] = useState(false);
  const [allGamesNames, setAllGamesName] = useState(new Set([]));

  const submitForm = async values => {
    console.log('Submitting...');
    console.log(values);
    console.log(Math.random().toString(36).slice(2));
    setIsLoading(true);
    try {
      let newValues = { ...values };
      await MissionsDataService.setMission(newValues);
      dispatch(editMission(newValues));
      dispatch(clearState());
      toast.success('Operatiunea s-a realizat cu succes!');
      setIsLoading(false);
      navigate('/missions');
    } catch (error) {
      console.log(error);

      toast.error('S-a produs o eraore. Incearca din nou.');
      setIsLoading(false);
    }
  };

  const removeGame = (values, setValues, index) => {
    console.log(index);

    let tmpGames = [...values.games];
    tmpGames.splice(index, 1);
    values.games = tmpGames;
    console.log(values);
    setValues(values);
  };

  const updateForm = (values, setValues) => {
    let newGames = [...values.games, ''];

    values.games = newGames;

    setValues(values);
  };

  const getGamesNames = async () => {
    const games = await GamesDataService.getAllGames();
    const categories = games.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    let gamesNames = new Set([]);
    let labelTracker = new Map(); // To track unique labels

    for (let category of categories) {
      for (let game_id in category.games) {
        const game = { label: category.games[game_id].name, id: game_id };

        if (!labelTracker.has(game.label)) {
          labelTracker.set(game.label, game);
        }
      }
    }
    gamesNames = new Set(labelTracker.values());
    setAllGamesName(gamesNames);
  };

  useEffect(() => {
    getGamesNames();
  }, []);

  return (
    <>
      <Container mt="3rem" maxW="container.lg">
        <VStack>
          <Link to="/missions">
            <Button
              leftIcon={<HiArrowNarrowLeft />}
              colorScheme="green"
              variant="outline"
              onClick={() => {
                dispatch(clearState());
              }}
            >
              Back
            </Button>
          </Link>
          <Spacer />
          <Heading textAlign={'center'} size="xl" py="3" mb="5">
            {missionDetails.prize === -1
              ? 'Adauga o noua misiune'
              : `Editeaza misiunea ${missionDetails.name}`}
          </Heading>
        </VStack>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="sm"
          my="2rem"
          p="2rem"
          maxW={'900px'}
        >
          <Box p={8}>
            <Formik
              initialValues={{
                description: missionDetails.description,
                endDate: missionDetails.endDate,
                games: missionDetails.games,
                imageURL: missionDetails.imageURL,
                isActive: missionDetails.isActive,
                name: missionDetails.name,
                prize: missionDetails.prize,
                toEditIndex: missionDetails.toEditIndex,
                id: missionDetails.id,
              }}
              onSubmit={values => {
                submitForm(values);
              }}
              validationSchema={MissionValidationSchema}
            >
              {({
                values,
                errors,
                touched,
                dirty,
                isValid,
                setFieldValue,
                setValues,
              }) => {
                return (
                  <Form>
                    <Input
                      mb={10}
                      label="Id"
                      isDisabled
                      name="id"
                      value={values.id}
                    />
                    <Input
                      mb={10}
                      label="Index misiune"
                      isDisabled
                      name="toEditIndex"
                      value={values.toEditIndex}
                    />
                    <FormField
                      errors={errors}
                      touched={touched}
                      as={Input}
                      name="name"
                      value={values.name}
                      label="Numele misiunii"
                      setFieldValue={setFieldValue}
                    />
                    <FormField
                      errors={errors}
                      touched={touched}
                      as={Textarea}
                      name="description"
                      value={values.description}
                      label="Descrierea misiunii"
                      setFieldValue={setFieldValue}
                    />
                    <FormField
                      type="datetime-local"
                      errors={errors}
                      touched={touched}
                      as={Input}
                      name="endDate"
                      value={values.endDate}
                      label="Finalul misiunii"
                      setFieldValue={setFieldValue}
                    />
                    <FormField
                      errors={errors}
                      touched={touched}
                      as={Input}
                      name="imageURL"
                      value={values.imageURL}
                      label="URL-ul imaginii misiunii"
                      setFieldValue={setFieldValue}
                    />

                    <FormControl mb={8} display="flex" alignItems="center">
                      <FormLabel htmlFor="is-active" mb="0">
                        Activezi misiunea? {values.isActive ? ' (DA)' : ' (NU)'}
                      </FormLabel>
                      <Field
                        type="checkbox"
                        name="isActive"
                        as={Switch}
                        id="is-active"
                      />
                      <Spacer />
                    </FormControl>

                    <FormField
                      type="number"
                      errors={errors}
                      touched={touched}
                      as={Input}
                      name="prize"
                      value={values.prize}
                      label="Premiul misiunii"
                      setFieldValue={setFieldValue}
                    />

                    {values.games.map((element, i) => {
                      return (
                        <Box key={`games-${i}`} marginLeft="10">
                          <HStack mb="10">
                            {i !== 0 ? (
                              <Button
                                onClick={e => removeGame(values, setValues, i)}
                                size="xs"
                                colorScheme="red"
                              >
                                <RiCloseCircleFill />
                              </Button>
                            ) : (
                              <Button
                                isDisabled={true}
                                size="xs"
                                colorScheme="gray"
                              >
                                <RiCloseCircleFill />
                              </Button>
                            )}

                            <ArrayFormFiledSuggestions
                              index={i}
                              errors={errors}
                              touched={touched}
                              as={Input}
                              name="joc"
                              value={{ label: element, id: '' }}
                              label={`Jocul ${i + 1}`}
                              setFieldValue={setFieldValue}
                              values={values}
                              options={allGamesNames}
                            />
                          </HStack>
                        </Box>
                      );
                    })}
                    {Array.isArray(errors.games) &&
                      errors.games.map((game, index) => {
                        return (
                          <Box>
                            <Heading mb="10" size="xs" color="red">
                              Jocul {index + 1}
                              {game &&
                                Object.keys(game).map(key => {
                                  return (
                                    <Text>
                                      - {key}: {game[key]}
                                    </Text>
                                  );
                                })}
                            </Heading>
                          </Box>
                        );
                      })}

                    <Button
                      colorScheme="teal"
                      onClick={e => updateForm(values, setValues)}
                    >
                      Adauga jocuri
                    </Button>

                    <Center mt={'4rem'}>
                      <Button
                        isDisabled={!isValid}
                        isLoading={isLoading}
                        minW={'200px'}
                        loadingText="Se actualizeaza"
                        type="submit"
                        colorScheme="green"
                        width="full"
                        textAlign="center"
                      >
                        {missionDetails.prize === -1 ? 'Adauga' : `Editeaza `}
                      </Button>
                    </Center>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <br />
      </Container>
    </>
  );
}

export default AddOrEditMission;

import React from 'react';
import { Main } from '../../components/Main/Main';
import { Flex } from '@chakra-ui/react';

function LogIn(props) {
  return (
    <Flex direction="column">
      <Main  />
      {/* <Footer /> */}
    </Flex>
  );
}

export default LogIn;

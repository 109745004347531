import React, { useState } from 'react';
import TombolaDataService from '../../services/tombola-services';
import {
  Heading,
  Container,
  Box,
  HStack,
  Spacer,
  SimpleGrid,
  Button,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Text,
  VStack,
  Center,
  Tooltip,
  Tag,
  Progress,
  Thead,
  Tfoot,
  TableCaption,
  Th,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiArrowNarrowLeft, HiCheckCircle } from 'react-icons/hi';
import { useEffect } from 'react';
import tombolaTickets from '../../services/tombola-tickets';
import { ToastContainer, toast } from 'react-toastify';
import UsersDataService from '../../services/users-services';
import { indexOf } from 'underscore';
import { FaPlus } from 'react-icons/fa';

function Tombola() {
  const [tombolaInfoState, setTombolaInfoState] = useState({
    isAvailable: false,
    ticketsBought: 0,
    participantCount: 0,
    prizes: [],
  });
  const [winnerIds, setWinnerIds] = useState([]);
  const [winnerEmails, setWinnerEmails] = useState([]);
  const [winnerTicketsIds, setWinnerTicketsIds] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [percentageExtraction, setPercentageExtraction] = useState(0);
  const [winnersVisible, setWinnersVisible] = useState(true);

  const [endYear, setEndYear] = useState(0);
  const [endMonth, setEndMonth] = useState(0);
  const [endDay, setEndDay] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [date, setDate] = useState(new Date());

  const [finalWinnersInfo, setFinalWinnersInfo] = useState({});

  const getTombolaInfo = async () => {
    let tmpTombolaInfo = await TombolaDataService.getTombolaInfo();
    tmpTombolaInfo = tmpTombolaInfo.data();
    tmpTombolaInfo.endDate = tmpTombolaInfo.endDate.toDate();
    setTombolaInfoState(tmpTombolaInfo);
    setEndYear(tmpTombolaInfo.endDate.getFullYear());
    setEndMonth(String(tmpTombolaInfo.endDate.getMonth() + 1).padStart(2, '0'));
    setEndDay(String(tmpTombolaInfo.endDate.getDate()).padStart(2, '0'));
  };

  const getWinnersInfo = async () => {
    const winners = await TombolaDataService.getAllWinners();
    let winnersId = [];
    let winnerTicketsIs = [];
    let winnerEmails = [];

    for (let doc of winners.docs) {
      let docData = doc.data();

      winnersId.push(docData.userId);
      winnerTicketsIs.push(docData.ticketId);
      winnerEmails.push(docData.userEmail);
    }

    setWinnerIds(winnersId);
    setWinnerTicketsIds(winnerTicketsIs);
    setWinnerEmails(winnerEmails);
  };

  const getTotalPsfPrizes = async () => {
    let total = 0;
    tombolaInfoState.prizes.map((element, index) => {
      if (
        element.name.includes('PAYSAFE') ||
        element.name.includes('Paysafe') ||
        element.name.includes('paysafe')
      ) {
        total = total + (element.toRank - element.fromRank + 1);
      }
    });
    return total;
  };

  useEffect(() => {
    console.log('in USE EFFECT');
    getTombolaInfo();
    getWinnersInfo();
  }, []);

  const delay = ms => new Promise(res => setTimeout(res, ms));

  const getAllTickets = async () => {
    const tickets = await TombolaDataService.getAllTickets();
    let ticketsArray = [];
    for (let doc of tickets.docs) {
      let tmp = {};
      tmp = { ...doc.data(), id: doc.id };
      ticketsArray.push(tmp);
    }
    return ticketsArray;
  };

  const extractWinners = async () => {
    setIsLoading3(true);
    let ticketsArray = await getAllTickets();
    // let ticketsArray = tombolaTickets;
    setWinnerIds([]);
    setWinnerTicketsIds([]);

    let ticketEntries = ticketsArray.length;
    let winnersId = [];
    let winnerEmails = [];
    let winnerTicketsIs = [];

    let totalPsfs = await getTotalPsfPrizes();
    let iteration = 1;
    setPercentageExtraction(parseInt((iteration / totalPsfs) * 100));

    while (winnersId.length < totalPsfs) {
      let winnerIndex = Math.floor(Math.random() * ticketEntries);
      let winnerUserId = ticketsArray[winnerIndex].userId;

      const userOccurence = winnersId.filter(i => i === winnerUserId).length;
      if (userOccurence < 2) {
        winnersId.push(winnerUserId);
        winnerTicketsIs.push(ticketsArray[winnerIndex].id);
        let auxEmail = await UsersDataService.getUserById(winnerUserId);
        if (auxEmail.data() !== undefined) {
          winnerEmails.push(auxEmail.data().email);
        } else {
          winnerEmails.push('');
        }

        setPercentageExtraction(parseInt((iteration / totalPsfs) * 100));
        iteration += 1;
        ticketsArray.splice(winnerIndex, 1);
        ticketEntries = ticketsArray.length;

        console.log(ticketsArray, ticketEntries);
      }
    }

    setWinnerIds(winnersId);
    setWinnerTicketsIds(winnerTicketsIs);
    setWinnerEmails(winnerEmails);

    setIsLoading3(false);
    await TombolaDataService.removeWinners();
    await TombolaDataService.storeWinners(
      winnersId,
      winnerTicketsIs,
      winnerEmails
    );
  };

  // const test1 = async () => {
  //   console.log(1);
  //   setIsLoading3(true);
  //   await delay(5000);
  //   setWinnersVisible(true);
  //   setIsLoading3(false);
  // };

  const getPrizeName = index => {
    let stringToReturn = '';
    tombolaInfoState.prizes.map(element => {
      if (index + 1 >= element.fromRank && index + 1 <= element.toRank) {
        stringToReturn = element.name;
      }
    });
    return stringToReturn;
  };

  const getPrizeRank = index => {
    let rankToReturn = -1;
    tombolaInfoState.prizes.map((element, rank) => {
      if (index + 1 >= element.fromRank && index + 1 <= element.toRank) {
        rankToReturn = rank;
      }
    });
    return rankToReturn;
  };

  const countFrequency = (arr, element) => {
    return arr.filter(ele => ele === element).length;
  };

  const getFinalWinnersMap = async () => {
    let finalWinnersInfo = {};

    for (let [index, e] of winnerIds.entries()) {
      if (finalWinnersInfo[e] !== undefined) {
        console.log(finalWinnersInfo[e]);
        finalWinnersInfo[e]['prizes'].push(getPrizeName(index));
        finalWinnersInfo[e]['tickets'].push(winnerTicketsIds[index]);
      } else {
        finalWinnersInfo[e] = {};
        finalWinnersInfo[e]['prizes'] = [getPrizeName(index)];
        finalWinnersInfo[e]['tickets'] = [winnerTicketsIds[index]];
        finalWinnersInfo[e]['email'] = winnerEmails[index];
      }
    }

    setFinalWinnersInfo(finalWinnersInfo);
  };

  const validateExtraction = async () => {
    setIsLoading(true);
    let iteration = 0;
    for (let ticketIndex in winnerTicketsIds) {
      iteration = iteration + 1;
      setPercentage(parseInt((iteration / winnerTicketsIds.length) * 100));
      let rank = getPrizeRank(parseInt(ticketIndex));
      await TombolaDataService.validateWinner(
        winnerTicketsIds[ticketIndex],
        rank
      );
    }
    setIsLoading(false);
    toast.success('Validarea s-a realizat cu succes!');
  };

  const deleteWinners = async () => {
    setIsLoading2(true);
    setPercentageExtraction(0);
    setPercentage(0);
    setWinnerIds([]);
    setWinnerTicketsIds([]);
    await TombolaDataService.removeWinners();
    setIsLoading2(false);
  };

  return (
    <Container mt="3rem" minWidth="95%">
      <Heading size="xl">
        Tombola {endYear}.{endMonth}.{endDay}
      </Heading>

      <HStack mt="3">
        <Link to="/admin-dashboard">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Dashboard
          </Button>
        </Link>
        <Spacer />
        <Link to="/create-new-ruffle">
          <Button colorScheme={'red'}>
            {' '}
            <FaPlus /> <Box w={2} />
            Creează o nouă tombolă
          </Button>
        </Link>
      </HStack>

      <Box
        mt="10"
        borderWidth="1px"
        borderRadius="sm"
        // boxShadow="sm"
        p="1rem"
      >
        <VStack tspacing={4} align="stretch">
          <HStack>
            <Heading size="lg">Informații tombolă</Heading>
            <Spacer />
          </HStack>

          <TableContainer>
            <Table size="md" variant="unstyled">
              <Tbody>
                <Tr>
                  <Td>
                    <Heading size="sm">Tombola activa</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    <Heading size="sm">
                      {tombolaInfoState.isAvailable ? 'DA' : 'NU'}
                    </Heading>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Heading size="sm">Participanti</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    <Heading size="sm">
                      {tombolaInfoState.participantCount}
                    </Heading>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Heading size="sm">Tichete</Heading>
                  </Td>
                  <Td sx={{ whiteSpace: 'normal' }}>
                    <Heading size="sm">
                      {tombolaInfoState.ticketsBought}
                    </Heading>
                  </Td>
                </Tr>
                <Box h={5} />
                <Heading size="lg">Premii</Heading>
                {tombolaInfoState.prizes.map((element, index) => {
                  return (
                    <Tr key={'premii-' + index}>
                      <Td>
                        <Heading size="sm">{element.name}</Heading>
                      </Td>
                      <Td sx={{ whiteSpace: 'normal' }}>
                        <Heading size="sm">
                          {element.toRank - element.fromRank + 1}
                        </Heading>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Center>
            <Box height={20}></Box>
            <HStack>
              <Button
                isLoading={isLoading2}
                size="md"
                colorScheme="teal"
                variant="outline"
                id={'fortune-wheel'}
                onClick={() => deleteWinners()}
              >
                Pregătește extragerea
              </Button>
              <Button
                isLoading={isLoading3}
                size="md"
                colorScheme="teal"
                variant="solid"
                id={'fortune-wheel'}
                onClick={() => extractWinners()}
              >
                Extrage câștigătorii
              </Button>
            </HStack>
          </Center>
        </VStack>
      </Box>

      <Progress colorScheme="green" hasStripe value={percentageExtraction} />

      {winnersVisible &&
        winnerIds.map((e, index) => {
          return (
            <Box
              mt="10"
              borderWidth="1px"
              borderRadius="sm"
              // boxShadow="sm"
              p="1rem"
              bg={'green.100'}
            >
              <HStack>
                <Heading size="md">#{index + 1}</Heading>
                <Text fontSize="xl">
                  Utilizatorul cu id-ul{' '}
                  <Link to={`/utilizatori/${e}`}>
                    <u>
                      <b>{e}</b>
                    </u>
                  </Link>{' '}
                  a câștigat cu tichetul <b>{winnerTicketsIds[index]}</b>{' '}
                  premiul:
                </Text>
              </HStack>
              <Center>
                <Heading mt={1} size="md">
                  {getPrizeName(index)}
                </Heading>
              </Center>
            </Box>
          );
        })}

      {winnerIds.length !== 0 && (
        <>
          <Button
            isDisabled={date < new Date('01-03-2024')}
            isLoading={isLoading}
            my={10}
            leftIcon={<HiCheckCircle />}
            colorScheme="green"
            size="lg"
            w={'100%'}
            fontSize={24}
            onClick={() => validateExtraction()}
          >
            VALIDEAZĂ EXTRAGEREA
          </Button>
          <Progress colorScheme="green" hasStripe value={percentage} />
        </>
      )}

      {winnerIds.length !== 0 && (
        <Center>
          <Button
            my={10}
            colorScheme="purple"
            onClick={() => getFinalWinnersMap()}
          >
            Genereaza tabel cu date utilizatori
          </Button>
        </Center>
      )}

      {Object.entries(finalWinnersInfo).length !== 0 && (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>ID Utilizator</Th>
                <Th>Email</Th>
                <Th>ID Ticket</Th>
                <Th>Numar premii</Th>
              </Tr>
            </Thead>
            <Tbody>
              {Object.entries(finalWinnersInfo).map(([k, v], index) => {
                return (
                  <Tr>
                    <Td>{index + 1}</Td>
                    <Td>
                      {' '}
                      <Link to={`/utilizatori/${k}`}>
                        <u>{k}</u>
                      </Link>{' '}
                    </Td>

                    <Td>{v.email}</Td>
                    {v.tickets && (
                      <Th>
                        {v.tickets.map((e, i) => {
                          return <Text>{e}</Text>;
                        })}
                      </Th>
                    )}
                    {v.prizes && (
                      <Th>
                        {v.prizes.map((e, i) => {
                          return <Text>{e}</Text>;
                        })}
                      </Th>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <ToastContainer
        position="top-right"
        theme="colored"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
    </Container>
  );
}

export default Tombola;

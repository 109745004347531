import React, { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Heading,
  IconButton,
} from '@chakra-ui/react';
import { HiOutlineTrash } from 'react-icons/hi';
import { FaTrash } from 'react-icons/fa';
const DeleteModal = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingState, setIsLoadingState] = useState(false);
  return (
    <>
      {props.icon === 'true' ? (
        <IconButton
          onClick={onOpen}
          colorScheme="red"
          aria-label="Delete Order"
          icon={<FaTrash />}
        />
      ) : (
        <Button
          size="sm"
          colorScheme="red"
          variant="solid"
          onClick={onOpen}
          width={props.explanation && 'full'}
        >
          Șterge {props.explanation}
        </Button>
      )}

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Dorești să ștergi?</ModalHeader>
          <ModalCloseButton />
          {props.type !== 'store' ? (
            <>
              <ModalBody>
                <br />
                Ești sigur că dorești să ștergi acest item? <br />
                <br />
                <Heading size="sm">{props.id}</Heading>
                <br />
                Acțiunea este definitivă și nu poate fi reparată.
              </ModalBody>

              <ModalFooter>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  mr={3}
                  onClick={onClose}
                >
                  Renunță
                </Button>
                <Button
                  id={props.id}
                  rightIcon={<HiOutlineTrash />}
                  colorScheme="red"
                  isLoading={isLoadingState}
                  onClick={async e => {
                    setIsLoadingState(true);
                    await props.deleteFunction(e.target.id);
                    onClose();
                    setIsLoadingState(false);
                  }}
                >
                  Da, șterge
                </Button>
              </ModalFooter>
            </>
          ) : props.productId !== undefined ? (
            <>
              {' '}
              <ModalBody>
                <br />
                Ești sigur că dorești să ștergi acest item? <br />
                <br />
                <Heading size="sm">{props.id}</Heading>
                <br />
                Acțiunea este definitivă și nu poate fi reparată.
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  mr={3}
                  onClick={onClose}
                >
                  Renunță
                </Button>
                <Button
                  id={props.id}
                  rightIcon={<HiOutlineTrash />}
                  colorScheme="red"
                  onClick={e => {
                    props.deleteFunction(e.target.id);
                    onClose();
                  }}
                >
                  Da, șterge
                </Button>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody>
                <br />
                Este necesar un refresh complet pentru a sterge acest item.{' '}
                <br />
                <br />
                <Heading size="sm">Dă-i un refresh la pagină.</Heading>
                <br />
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="outline"
                  colorScheme="gray"
                  mr={3}
                  onClick={onClose}
                >
                  Ok
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModal;

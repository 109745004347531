import { yupToFormErrors } from "formik";
import * as Yup from "yup";

const AddOrEditGameMetaSchema = Yup.object().shape({
  title: Yup.string().required("Titlul este necesar"),
  icon: Yup.string().required("Iconita categoriei cazinoului este necesar"),
  priority: Yup.number()
    .typeError("Prioritatea trebuie sa fie un numar")
    .min(0, "Prioritatea trebuie sa fie pozitiva")
    .lessThan(20, "Prioritatea trebuie sa fie mai mica decat 20")
    .required("Prioritatea este necesara"),
});

export default AddOrEditGameMetaSchema;

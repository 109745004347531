import { db } from './firebase-users-config';

import {
  collection,
  getDocs,
  query,
  doc,
  updateDoc,
  arrayRemove,
  setDoc,
  getDoc,
  deleteDoc,
  orderBy,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';

const convertToHTMLInputDate = dateString => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date string');
  }

  // Pad single digit minutes and hours with a leading zero
  const pad = num => num.toString().padStart(2, '0');

  // Extract components
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are 0-based, so add 1
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());

  // Combine components into the desired format
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const char_set =
  'abcdefghijlkmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
function max_random_number(max) {
  return Math.floor(Math.random() * max);
}

const get_random_string = length => {
  let random_string = '';
  for (let i = 0; i < length; i++) {
    random_string += char_set[max_random_number(char_set.length - 1)];
  }

  return random_string;
};

class MissionsDataService {
  getAllMissions = async () => {
    const missionsCollectionRef = collection(db, 'missions');
    try {
      const querySnapshot = await getDocs(missionsCollectionRef);
      return querySnapshot.docs.map(doc => {
        let auxData = {
          id: doc.id,
          ...doc.data(),
        };

        const tmpDate = convertToHTMLInputDate(
          new Date(auxData.endDate.toDate())
        );
        delete auxData.endDate;

        return { id: doc.id, ...auxData, endDate: tmpDate };
      });
    } catch (error) {
      console.error('Error fetching documents: ', error);
      throw error;
    }
  };

  setMission = async missionData => {
    let auxMissionData = { ...missionData };
    let id = auxMissionData.id;

    if (id === '' || id === undefined) {
      id = get_random_string(20);
    }

    console.log(id);

    const missionDoc = doc(db, 'missions', id);

    delete auxMissionData['id'];
    delete auxMissionData['toEditIndex'];

    auxMissionData['endDate'] = Timestamp.fromDate(
      new Date(auxMissionData['endDate'])
    );

    if (auxMissionData.isActive === false) {
      console.log('Flag is false; Updating the document.');

      return setDoc(missionDoc, auxMissionData);
    } else {
      const missionsCollection = collection(db, 'missions');
      const missionsSnapshot = await getDocs(missionsCollection);

      missionsSnapshot.forEach(async missionDoc => {
        const missionRef = doc(db, 'missions', missionDoc.id);
        await updateDoc(missionRef, { isActive: false });
        console.log(`Deactivated mission: ${missionDoc.id}`);
      });

      return setDoc(missionDoc, auxMissionData);
    }
  };
}
export default new MissionsDataService();

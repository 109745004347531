import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: '',
  isLoggedIn: false,
  isAdmin: false,
};

const checkCurrentUserSlice = createSlice({
  name: 'checkCurrentUser',
  initialState,
  reducers: {
    setCurrentUser: (state, { payload }) => {
      state.userId = payload.userId;
      state.isLoggedIn = payload.isLoggedIn;
      state.isAdmin = payload.isAdmin;
    },
  },
});

export default checkCurrentUserSlice.reducer;
export const { setCurrentUser } = checkCurrentUserSlice.actions;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  add: true,
  edited: false,
  id: {
    value: '',
    showUiError: false,
  },
  name: {
    value: '',
    showUiError: false,
  },
  title1: {
    value: '',
    showUiError: false,
  },
  text1: {
    value: '',
    showUiError: false,
  },
  title2: {
    value: '',
    showUiError: false,
  },
  text2: {
    value: '',
    showUiError: false,
  },
  imageURL: {
    value: '',
    showUiError: false,
  },
  webURL: {
    value: '',
    showUiError: false,
  },
  buttonText: {
    value: '',
    showUiError: false,
  },
  isPublished: {
    value: false,
    showUiError: false,
  },
  isIOSPublished: {
    value: false,
    showUiError: false,
  },
  priority: {
    value: 1,
    showUiError: false,
  },
  errors: {
    somethingIsEmpty: true,
    notMatchingTitleAndText: true,
    isNotANumber: false,
  },
};

const addPromoItemSlice = createSlice({
  name: 'addPromoItem',
  initialState,
  reducers: {
    clearState: state => {
      state.add = true;
      state.edited = false;
      state.id = {
        value: '',
        showUiError: false,
      };
      state.name = {
        value: '',
        showUiError: false,
      };
      state.title1 = {
        value: '',
        showUiError: false,
      };
      state.text1 = {
        value: '',
        showUiError: false,
      };
      state.title2 = {
        value: '',
        showUiError: false,
      };
      state.text2 = {
        value: '',
        showUiError: false,
      };
      state.imageURL = {
        value: '',
        showUiError: false,
      };
      state.webURL = {
        value: '',
        showUiError: false,
      };
      state.buttonText = {
        value: '',
        showUiError: false,
      };
      state.isPublished = {
        value: false,
        showUiError: false,
      };
      state.isIOSPublished = {
        value: false,
        showUiError: false,
      };
      state.priority = {
        value: 1,
        showUiError: false,
      };
      state.errors = {
        somethingIsEmpty: true,
        notMatchingTitleAndText: true,
        isNotANumber: false,
      };
    },
    prepareStateForEdit: (state, { payload }) => {
      state.add = false;
      state.edited = false;
      state.id = {
        value: payload.id,
        showUiError: false,
      };
      state.name = {
        value: payload.name,
        showUiError: false,
      };
      state.title1 = {
        value: payload.titlesArray[0],
        showUiError: false,
      };
      state.text1 = {
        value: payload.textsArray[0],
        showUiError: false,
      };
      state.title2 = {
        value: payload.titlesArray[1] ? payload.titlesArray[1] : '',
        showUiError: false,
      };
      state.text2 = {
        value: payload.textsArray[1] ? payload.textsArray[1] : '',
        showUiError: false,
      };
      state.imageURL = {
        value: payload.imageURL,
        showUiError: false,
      };
      state.webURL = {
        value: payload.webURL,
        showUiError: false,
      };
      state.buttonText = {
        value: payload.buttonText,
        showUiError: false,
      };
      state.isPublished = {
        value: payload.isPublished,
        showUiError: false,
      };
      state.isIOSPublished = {
        value: payload.isIOSPublished,
        showUiError: false,
      };
      state.priority = {
        value: payload.priority,
        showUiError: false,
      };
      state.errors = {
        somethingIsEmpty: true,
        notMatchingTitleAndText: true,
        isNotANumber: false,
      };
    },
    
    updateState: (state, { payload }) => {
      state[payload.name].value = payload.value;
      state.edited = true;
    },
  },
});

export default addPromoItemSlice.reducer;
export const { clearState, updateState, prepareStateForEdit } =
  addPromoItemSlice.actions;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  description: '',
  endDate: new Date().toLocaleDateString('ro-RO'),
  games: [''],
  imageURL: '',
  isActive: false,
  name: '',
  prize: -1,
  toEditIndex: -2,
};

const addOrEditMissionItemsSlice = createSlice({
  name: 'addOrEditMissionItems',
  initialState,
  reducers: {
    clearState: state => {
      state.id = '';
      state.description = '';
      state.endDate = new Date().toLocaleDateString('ro-RO');
      state.games = [''];
      state.imageURL = '';
      state.isActive = false;
      state.name = '';
      state.prize = -1;
      state.toEditIndex = -1;
    },
    prepareStateForEdit: (state, { payload }) => {
      console.log(payload);
      state.id = payload.id;
      state.description = payload.description;
      state.endDate = payload.endDate;
      state.games = payload.games;
      state.imageURL = payload.imageURL;
      state.isActive = payload.isActive;
      state.name = payload.name;
      state.prize = payload.prize;
      state.toEditIndex = payload.index;
    },
    updateState: (state, { payload }) => {
      state[payload.name] = payload.value;
    },
  },
});

export default addOrEditMissionItemsSlice.reducer;
export const { clearState, updateState, prepareStateForEdit } =
  addOrEditMissionItemsSlice.actions;

import React from 'react';
import DeleteModal from '../../../components/Modal/Modal';
import {
  VStack,
  Heading,
  Box,
  Text,
  Table,
  Tbody,
  Tr,
  Td,
  TableContainer,
  HStack,
  Spacer,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { removeItemFromShop } from '../../../features/storeItems/rememberStoreItems';
import {
  clearState,
  updateState,
  prepareStateForEdit,
} from '../../../features/storeItems/addOrEditStoreItems';

import NewStoreDataService from '../../../services/new-store-services';

function StoreAccordion(props) {
  const dispatch = useDispatch();
  const generalState = useSelector(state => state.rememberStoreItems);

  console.log(props);

  // LOGIC
  const deleteButtonClicked = async e => {
    try {
      const id = e;
      let index = id.split('_')[0];
      let store_id = id.split('_')[1];
      let product_id = id.split('_')[2];

      await NewStoreDataService.deleteStoreItem(store_id, product_id);
      dispatch(removeItemFromShop({ index: index, id: store_id }));
      toast.success('Operatiunea s-a realizat cu succes!');
    } catch (error) {
      console.log(error);
      toast.error('S-a produs o eroare. Incearca din nou.');
    }
  };

  const editButtonClicked = e => {
    const id = e.target.id;
    let index = id.split('-')[0];
    let store_id = id.split('-')[1];
    let product_id = id.split('-')[2];
    console.log(generalState[store_id].products[index]);

    dispatch(
      prepareStateForEdit({
        ...generalState[store_id].products[index],
        id: store_id,
        index: index,
        productId: product_id,
      })
    );
  };

  return (
    <>
      <Accordion allowToggle>
        {props.products.map((doc, index) => {
          return (
            <AccordionItem key={index}>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  <HStack>
                    <Box ml={'20px'}></Box>
                    <Heading size="sm">{doc.name}</Heading>
                  </HStack>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box
                  key={doc.name}
                  borderWidth="1px"
                  borderRadius="sm"
                  // boxShadow="sm"
                  p="1rem"
                >
                  <VStack tspacing={4} align="stretch">
                    <HStack as={'span'}>
                      <Link to="/editeaza-item-magazin">
                        <Button
                          size="sm"
                          colorScheme="teal"
                          variant="solid"
                          id={`${index}-${props.whichIs}-${doc.id}`}
                          onClick={e => editButtonClicked(e)}
                        >
                          Editeaza
                        </Button>
                      </Link>
                      <Spacer />
                      <DeleteModal
                        deleteFunction={deleteButtonClicked}
                        productId={doc.id}
                        type="store"
                        id={`${index}_${props.whichIs}_${doc.id}`}
                      />
                    </HStack>
                    <TableContainer>
                      <Table size="md" variant="unstyled">
                        <Tbody>
                          <Tr>
                            <Td>
                              <Heading size="sm">Nume:</Heading>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Heading size="sm">{doc.name}</Heading>
                            </Td>
                          </Tr>

                          <Tr>
                            <Td>
                              <Text fontSize="sm">Descriere produs</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.description}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">Pret produs</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.price}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">Imagine produs</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.image}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">Stoc disponibil</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.stock}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">Tip Produs</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.type}</Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">URL Redirectare</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">
                                {doc.redirectURL !== '' ? doc.redirectURL : '-'}
                              </Text>
                            </Td>
                          </Tr>
                          <Tr>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">Data Livrare</Text>
                            </Td>
                            <Td sx={{ whiteSpace: 'normal' }}>
                              <Text fontSize="sm">{doc.deliveryDate}</Text>
                            </Td>
                          </Tr>
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </VStack>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}

export default StoreAccordion;

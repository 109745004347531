import { db } from './firebase-users-config';

import {
  collection,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  doc,
  orderBy,
  query,
} from 'firebase/firestore';

const promoCarouselCollectionRef = collection(db, 'promoCarousel');
// const bonusesCollectionRef = collection(db, 'bonuses');
// const gameCategoriesCollectionRef = collection(db, 'gameCategories');

class PromoCarouselDataService {
  addPromoCarousel = (newPromoCarousel, id) => {
    return setDoc(doc(db, 'promoCarousel', id), newPromoCarousel);
  };

  updatePromoCarousel = (id, updatedPromoCarousel) => {
    const promoCarouselDoc = doc(db, 'promoCarousel', id);
    return updateDoc(promoCarouselDoc, updatedPromoCarousel);
  };

  deletePromoCarousel = id => {
    const promoCarouselDoc = doc(db, 'promoCarousel', id);
    return deleteDoc(promoCarouselDoc);
  };

  getAllPromoCarousels = () => {
    return getDocs(
      query(
        promoCarouselCollectionRef,
        orderBy('isPublished', 'desc'),
        orderBy('name')
      )
    );
  };

  getPromoCarousel = id => {
    const promoCarouselDoc = doc(db, 'promoCarousel', id);
    return getDoc(promoCarouselDoc);
  };
}

export default new PromoCarouselDataService();

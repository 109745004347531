import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const AddOrEditSchema = Yup.object().shape({
  id: Yup.string().required('Id-ul este necesar'),
  name: Yup.string().required('Numele este necesar'),
  title1: Yup.string().required('Titlul paragrafului este necesar'),
  text1: Yup.string().required('Textul paragrafului este necesar'),
  title2: Yup.string(),
  text2: Yup.string(),
  imageURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul imaginii este necesar'),
  webURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul paginii web este necesar'),
  buttonText: Yup.string().required('Textul butonului este necesar'),
  priority: Yup.number()
    .typeError('Prioritatea trebuie sa fie un numar')
    .min(0, 'Prioritatea trebuie sa fie pozitiva')
    .lessThan(20, 'Prioritatea trebuie sa fie mai mica decat 20')
    .required('Prioritatea este necesara'),
});

export default AddOrEditSchema;
// .when('text2', {
//     is: value => !!value,
//     then: Yup.string().required('Required'),
//   })

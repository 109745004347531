import React from 'react';
import OrdersDataService from '../../services/orders-services';
import { useEffect, useState } from 'react';
import {
  setOrderItems,
  setOrderStatus,
  setOrderItemsArrays,
  removeItemFromOrder,
} from '../../features/orderItems/rememberOrderItemsSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  Text,
  Heading,
  Container,
  HStack,
  Button,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Spinner,
  Box,
  Tr,
  Th,
  Td,
  Tooltip,
  Center,
  TableCaption,
  TableContainer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  IconButton,
  Switch,
  Spacer,
  Flex,
  VStack,
  SimpleGrid,
} from '@chakra-ui/react';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { CheckCircleIcon, EmailIcon } from '@chakra-ui/icons';
import { FaCartArrowDown, FaTrash } from 'react-icons/fa';
import DeleteModal from '../../components/Modal/Modal';
import { ToastContainer, toast } from 'react-toastify';

function Comenzi() {
  const dispatch = useDispatch();

  const merchandise = useSelector(state => state.rememberOrderItems.merchItems);
  // const digitalProducts = useSelector(
  //   state => state.rememberOrderItems.digitalItems
  // );
  const casinoOffers = useSelector(
    state => state.rememberOrderItems.casinoItems
  );
  // const paysafeCards = useSelector(
  //   state => state.rememberOrderItems.paysafelItems
  // );

  const [showOnlyUnprocessed, setshowOnlyUnprocessed] = useState(false);

  const [filteredOrdersMap, setFilteredOrdersMap] = useState({
    // filteredOrdersM: merchandise,
    // filteredOrdersP: paysafeCards,
    filteredOrdersC: casinoOffers,
    // filteredOrdersD: digitalProducts,
  });

  const [filteredCasino, setFilteredCasino] = useState('');

  const getOrdersItems = async () => {
    console.log('Getting items...');
    try {
      const data = await OrdersDataService.getAllOrders();
      let tmp = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

      let ordersM = [];
      let ordersD = [];
      let ordersP = [];
      let ordersC = [];

      for (let user in tmp) {
        let newOrder = {};
        for (let [id, orderDetails] of Object.entries(tmp[user]['orders'])) {
          let dateOfLastStatusChange =
            orderDetails.dateOfLastStatusChange.toMillis();
          let dateOfOrderPlacement =
            orderDetails.dateOfOrderPlacement.toMillis();
          newOrder = {
            ...orderDetails,
            dateOfLastStatusChange: dateOfLastStatusChange,
            dateOfOrderPlacement: dateOfOrderPlacement,
          };
          tmp[user]['orders'][id] = newOrder;
          newOrder = {
            ...newOrder,
            email: tmp[user].email,
            id: tmp[user].id,
            orderId: id,
          };

          if (newOrder.itemCategory === 'merchandise') {
            ordersM.push(newOrder);
          } else if (newOrder.itemCategory === 'digitalProducts') {
            ordersD.push(newOrder);
          } else if (newOrder.itemCategory === 'casinoOffers') {
            ordersC.push(newOrder);
          } else if (newOrder.itemCategory === 'paysafeCards') {
            ordersP.push(newOrder);
          }
        }
      }

      dispatch(
        setOrderItems({
          // merchItems: ordersM,
          digitalItems: ordersD,
          paysafelItems: ordersP,
          casinoItems: ordersC,
        })
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (casinoOffers.length === 0) {
      console.log('Accessing the database');
      getOrdersItems();
    } else {
      console.log('Restoring from store');
    }
  }, []);

  useEffect(() => {
    if (showOnlyUnprocessed === true) {
      setFilteredOrdersMap({
        // filteredOrdersM: merchandise.filter(
        //   order => order.orderStatus === 'order-created'
        // ),
        // filteredOrdersP: paysafeCards.filter(
        //   order => order.orderStatus === 'order-created'
        // ),
        filteredOrdersC: casinoOffers.filter(
          order => order.orderStatus === 'order-created'
        ),
        // filteredOrdersD: digitalProducts.filter(
        //   order => order.orderStatus === 'order-created'
        // ),
      });
    } else {
      setFilteredOrdersMap({
        // filteredOrdersM: merchandise,
        // filteredOrdersP: paysafeCards,
        filteredOrdersC: casinoOffers,
        // filteredOrdersD: digitalProducts,
      });
    }
  }, [
    showOnlyUnprocessed,
    merchandise,
    // digitalProducts,
    casinoOffers,
    // paysafeCards,
  ]);

  // Logic

  const orderButtonClicked = async (element, category) => {
    let orderStatus = element.orderStatus;
    let userId = element.id;
    let orderId = element.orderId;

    let newOrdeStatus =
      orderStatus === 'order-created' ? 'order-shipped' : 'order-created';

    dispatch(
      setOrderStatus({
        orderStatus: newOrdeStatus,
        category: category,
        userId: userId,
        orderId: orderId,
      })
    );

    try {
      await OrdersDataService.updateOrderStatus(element, newOrdeStatus);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteButtonClicked = async id => {
    const [userMail, orderCategory, userId, orderId] = id.split('_');

    await OrdersDataService.deleteOrder(userMail, userId, orderId);
    dispatch(
      removeItemFromOrder({ id: id, orderId: orderId, category: orderCategory })
    );
    toast.success('Operatiunea s-a realizat cu succes!');
  };

  // UI

  const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
    <span ref={ref} {...rest}>
      {children}
    </span>
  ));

  const tableHeadCazino = (
    <Thead>
      <Tr>
        <Th>Data Comanda / Ultima Modificare</Th>
        <Th>User</Th>
        <Th>Produs</Th>
        <Th>Status Comanda</Th>
        <Th>Action</Th>
      </Tr>
    </Thead>
  );

  return (
    <Container mt="3rem" minWidth="95%">
      <Heading size="xl">Shop Orders Dashboard</Heading>
      <HStack mt="3">
        <Link to="/magazin-dashboard">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Shop Menu
          </Button>
        </Link>
      </HStack>
      <HStack>
        <Spacer />
        <Text>Arata doar comenzile netrimise?</Text>
        <Switch
          defaultChecked={showOnlyUnprocessed}
          onChange={() => {
            setshowOnlyUnprocessed(!showOnlyUnprocessed);
            console.log(showOnlyUnprocessed);
          }}
          size="md"
        />
      </HStack>
      <Tabs mt="7" isFitted variant="enclosed" colorScheme="green">
        <TabList mb="1em">
          <Tab>
            <Heading size={'sm'}>Cazinouri</Heading>
          </Tab>
        </TabList>
        <TabPanels>
          {Object.entries(filteredOrdersMap).map((filteredArray, index) => {
            return (
              <>
                <TabPanel>
                  {filteredArray[0] === 'filteredOrdersC' && (
                    <VStack
                      align="flex-start"
                      alignItems="flex-start"
                      verticalAlign="top"
                      textAlign={'left'}
                    >
                      <Button
                        colorScheme="gray"
                        size="sm"
                        onClick={() => setFilteredCasino('')}
                      >
                        {filteredCasino === ''
                          ? 'Nu sunt filtre active'
                          : 'Sterge Filtre'}
                      </Button>
                      <Box h={7} />
                      <SimpleGrid columns={7} spacing="5px">
                        <Button
                          colorScheme="yellow"
                          size="xs"
                          isActive={filteredCasino === 'LASVEGAS'}
                          onClick={() => setFilteredCasino('LASVEGAS')}
                        >
                          LASVEGAS
                        </Button>
                        <Button
                          colorScheme="red"
                          size="xs"
                          isActive={filteredCasino === 'SUPERBET'}
                          onClick={() => setFilteredCasino('SUPERBET')}
                        >
                          SUPERBET
                        </Button>
                        <Button
                          colorScheme="orange"
                          size="xs"
                          isActive={filteredCasino === 'BETANO'}
                          onClick={() => setFilteredCasino('BETANO')}
                        >
                          BETANO
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          isActive={filteredCasino === '888'}
                          onClick={() => setFilteredCasino('888')}
                        >
                          888
                        </Button>{' '}
                        <Button
                          colorScheme="purple"
                          size="xs"
                          isActive={filteredCasino === 'VLADCAZINO'}
                          onClick={() => setFilteredCasino('VLADCAZINO')}
                        >
                          VLADCAZINO
                        </Button>{' '}
                        <Button
                          colorScheme="green"
                          size="xs"
                          isActive={filteredCasino === 'UNIBET'}
                          onClick={() => setFilteredCasino('UNIBET')}
                        >
                          UNIBET
                        </Button>{' '}
                        <Button
                          colorScheme="yellow"
                          size="xs"
                          isActive={filteredCasino === 'MAXBET'}
                          onClick={() => setFilteredCasino('MAXBET')}
                        >
                          MAXBET
                        </Button>{' '}
                        <Button
                          colorScheme="red"
                          size="xs"
                          isActive={filteredCasino === 'NETBET'}
                          onClick={() => setFilteredCasino('NETBET')}
                        >
                          NETBET
                        </Button>{' '}
                        <Button
                          colorScheme="green"
                          size="xs"
                          isActive={filteredCasino === 'GETSBET'}
                          onClick={() => setFilteredCasino('GETSBET')}
                        >
                          GETSBET
                        </Button>{' '}
                        <Button
                          colorScheme="blue"
                          size="xs"
                          isActive={filteredCasino === 'SPORTINGBET'}
                          onClick={() => setFilteredCasino('SPORTINGBET')}
                        >
                          SPORTINGBET
                        </Button>{' '}
                        <Button
                          colorScheme="purple"
                          size="xs"
                          isActive={filteredCasino === 'MRBIT'}
                          onClick={() => setFilteredCasino('MRBIT')}
                        >
                          MRBIT
                        </Button>{' '}
                        <Button
                          colorScheme="red"
                          size="xs"
                          isActive={filteredCasino === 'WINMASTERS'}
                          onClick={() => setFilteredCasino('WINMASTERS')}
                        >
                          WINMASTERS
                        </Button>
                        <Button
                          colorScheme="gray"
                          size="xs"
                          isActive={filteredCasino === 'POKERSTARS'}
                          onClick={() => setFilteredCasino('POKERSTARS')}
                        >
                          POKERSTARS
                        </Button>
                        <Button
                          colorScheme="blue"
                          size="xs"
                          isActive={filteredCasino === 'MOZZARTBET'}
                          onClick={() => setFilteredCasino('MOZZARTBET')}
                        >
                          MOZZARTBET
                        </Button>{' '}
                        <Button
                          colorScheme="pink"
                          size="xs"
                          isActive={filteredCasino === 'PLAYER'}
                          onClick={() => setFilteredCasino('PLAYER')}
                        >
                          PLAYER
                        </Button>{' '}
                        <Button
                          colorScheme="yellow"
                          size="xs"
                          isActive={filteredCasino === 'MILLION'}
                          onClick={() => setFilteredCasino('MILLION')}
                        >
                          MILLION
                        </Button>{' '}
                      </SimpleGrid>
                      <Box h={7} />
                    </VStack>
                  )}
                  <TableContainer mt="5">
                    <Table variant="simple">
                      {tableHeadCazino}
                      <br />
                      <Tbody>
                        {filteredArray[1].length === 0 ? (
                          <Spinner />
                        ) : (
                          filteredArray[1]
                            .filter(element =>
                              filteredArray[0] === 'filteredOrdersC'
                                ? filteredCasino !== ''
                                  ? element.productType === filteredCasino
                                  : element
                                : element
                            )
                            .map((element, index) => {
                              return (
                                <>
                                  {filteredArray[0] === 'filteredOrdersC' && (
                                    <Tr>
                                      <Heading size="sm">
                                        {element.productType}:
                                      </Heading>
                                      {element.casinoUsername}{' '}
                                      {element.casinoEmail}
                                    </Tr>
                                  )}
                                  <Tr colSpan={3}>
                                    <Td>
                                      #{index + 1} - ID:{' '}
                                      <Link to={`/utilizatori/${element.id}`}>
                                        <u>
                                          <b>{element.id}</b>
                                        </u>
                                      </Link>
                                    </Td>
                                  </Tr>

                                  <Tr>
                                    <Td>
                                      {new Date(
                                        element.dateOfOrderPlacement
                                      ).toLocaleDateString('ro-RO')}{' '}
                                      {new Date(
                                        element.dateOfOrderPlacement
                                      ).toLocaleTimeString('ro-RO')}{' '}
                                      <hr />
                                      {new Date(
                                        element.dateOfLastStatusChange
                                      ).toLocaleDateString('ro-RO')}{' '}
                                      {new Date(
                                        element.dateOfLastStatusChange
                                      ).toLocaleTimeString('ro-RO')}
                                    </Td>

                                    <Td>{element.email}</Td>
                                    <Td>{element.productName}</Td>
                                    <Td>{element.orderStatus}</Td>
                                    <Td>
                                      <Tooltip
                                        label={
                                          element.orderStatus ===
                                          'order-created'
                                            ? 'Finalizează comanda.'
                                            : 'Comanda a fost trimisă.'
                                        }
                                      >
                                        <CustomCard>
                                          <IconButton
                                            onClick={() =>
                                              orderButtonClicked(
                                                element,
                                                filteredArray[0]
                                              )
                                            }
                                            colorScheme={
                                              element.orderStatus ===
                                              'order-created'
                                                ? 'red'
                                                : 'green'
                                            }
                                            aria-label="Acknowledge Order"
                                            icon={
                                              element.orderStatus ===
                                              'order-created' ? (
                                                <FaCartArrowDown />
                                              ) : (
                                                <CheckCircleIcon />
                                              )
                                            }
                                          />{' '}
                                        </CustomCard>
                                      </Tooltip>{' '}
                                      <Tooltip label="Trimite un mail catre user.">
                                        <CustomCard>
                                          <a href={`mailto:${element.email}`}>
                                            <IconButton
                                              colorScheme="purple"
                                              aria-label="Acknowledge Order"
                                              icon={<EmailIcon />}
                                            />
                                          </a>
                                        </CustomCard>
                                      </Tooltip>{' '}
                                      <Tooltip label="Sterge comanda">
                                        <CustomCard>
                                          <DeleteModal
                                            id={`${element.email}_${element.itemCategory}_${element.id}_${element.orderId}`}
                                            deleteFunction={deleteButtonClicked}
                                            icon="true"
                                          />
                                        </CustomCard>
                                      </Tooltip>
                                    </Td>
                                  </Tr>
                                  <br />
                                </>
                              );
                            })
                        )}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              </>
            );
          })}
        </TabPanels>
      </Tabs>
      <ToastContainer
        position="top-right"
        theme="colored"
        pauseOnHover={false}
        pauseOnFocusLoss={false}
      />
    </Container>
  );
}

export default Comenzi;

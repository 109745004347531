import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearState,
  updateState,
} from '../../features/casionoItems/addOrEditCasinoItemsSlice';
import {
  addCasinoItem,
  editCazinoItem,
} from '../../features/casionoItems/rememberCasinoItems';
import { Formik, Form, Field } from 'formik';

import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Select,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import AddOrEditSchema from './AddOrEditCazinoValidationSchema';
import CasinosDataService from '../../services/casinos-services';

function AddOrEditCazino(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const add = useSelector(state => state.addOrEditCasinoItems.add);
  const index = useSelector(state => state.addOrEditCasinoItems.index);
  const id = useSelector(state => state.addOrEditCasinoItems.id);
  const bonusURL = useSelector(state => state.addOrEditCasinoItems.bonusURL);
  const disclaimer = useSelector(
    state => state.addOrEditCasinoItems.disclaimer
  );
  const logoURL = useSelector(state => state.addOrEditCasinoItems.logoURL);
  const name = useSelector(state => state.addOrEditCasinoItems.name);
  const primaryBonus = useSelector(
    state => state.addOrEditCasinoItems.primaryBonus
  );
  const rating = useSelector(state => state.addOrEditCasinoItems.rating);
  const reviewURL = useSelector(state => state.addOrEditCasinoItems.reviewURL);
  const secondaryBonus = useSelector(
    state => state.addOrEditCasinoItems.secondaryBonus
  );
  const welcomeBonusHeader = useSelector(
    state => state.addOrEditCasinoItems.welcomeBonusHeader
  );

  const generalRememberStore = useSelector(state => state.rememberCasinoItems);

  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async values => {
    setIsLoading(true);

    const newCazinoItem = {
      name: values.name !== undefined ? values.name.trim() : '',
      primaryBonus:
        values.primaryBonus !== undefined ? values.primaryBonus.trim() : '',
      secondaryBonus:
        values.secondaryBonus !== undefined ? values.secondaryBonus.trim() : '',
      welcomeBonusHeader:
        values.welcomeBonusHeader !== undefined
          ? values.welcomeBonusHeader.trim()
          : '',
      disclaimer:
        values.disclaimer !== undefined ? values.disclaimer.trim() : '',
      bonusURL: values.bonusURL !== undefined ? values.bonusURL.trim() : '',
      reviewURL: values.reviewURL !== undefined ? values.reviewURL.trim() : '',
      logoURL: values.logoURL !== undefined ? values.logoURL.trim() : '',
      rating: parseInt(values.rating),
    };
    try {
      if (add === true) {
        await CasinosDataService.addCazino(newCazinoItem, values.id);
        dispatch(addCasinoItem({ cazino: newCazinoItem, id: values.id }));
        dispatch(clearState());
      } else {
        console.log(index);
        await CasinosDataService.editCazino(
          newCazinoItem,
          values.id,
          index,
          generalRememberStore[values.id].cazinos
        );
        dispatch(
          editCazinoItem({ cazino: newCazinoItem, id: values.id, index: index })
        );
        dispatch(clearState());
      }

      toast.success('Operatiunea s-a realizat cu succes!');
      setIsLoading(false);
      navigate('/casino-items');
    } catch (e) {
      console.log(e);
      toast.error('S-a produs o eraore. Incearca din nou.');
      setIsLoading(false);
    }
  };

  return (
    <Container mt="3rem" maxW="container.lg">
      <VStack>
        <Link to="/casino-items">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
            onClick={() => {
              dispatch(clearState());
            }}
          >
            Back
          </Button>
        </Link>
        <Spacer />
        <Heading textAlign={'center'} size="xl" py="3" mb="5">
          {add
            ? 'Adauga un item la cazinouri'
            : 'Editeaza cazinoul din aplicatie'}
        </Heading>
      </VStack>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="sm"
        my="2rem"
        p="2rem"
        maxW={'900px'}
      >
        <Box p={8}>
          <Formik
            initialValues={{
              id: id.value,
              name: name.value,
              primaryBonus: primaryBonus.value,
              secondaryBonus: secondaryBonus.value,
              welcomeBonusHeader: welcomeBonusHeader.value,
              disclaimer: disclaimer.value,
              logoURL: logoURL.value,
              bonusURL: bonusURL.value,
              reviewURL: reviewURL.value,
              rating: rating.value,
            }}
            onSubmit={values => {
              submitForm(values);
            }}
            validationSchema={AddOrEditSchema}
          >
            {({ values, errors, touched, dirty, isValid, setFieldValue }) => {
              return (
                <Form>
                  <FormControl
                    isInvalid={errors.id && touched.id}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Select}
                      name="id"
                      isDisabled={add ? false : true}
                      value={values.id}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('id', e.target.value);
                      }}
                      placeholder=" "
                    >
                      <option value="topCazinos">topCazinos</option>
                      <option value="noDeposit">noDeposit</option>
                    </Field>
                    <FormLabel>ID cazino</FormLabel>
                    {errors.id && touched.id ? (
                      <FormErrorMessage>{errors.id}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.name && touched.name}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="name"
                      value={values.name}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('name', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Nume cazino</FormLabel>
                    {errors.name && touched.name ? (
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.primaryBonus && touched.primaryBonus}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="primaryBonus"
                      value={values.primaryBonus}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('primaryBonus', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Bonus Primar</FormLabel>
                    {errors.primaryBonus && touched.primaryBonus ? (
                      <FormErrorMessage>{errors.primaryBonus}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.secondaryBonus && touched.secondaryBonus}
                    mb={8}
                    variant="floating"
                  >
                    <Field
                      as={Input}
                      name="secondaryBonus"
                      value={values.secondaryBonus}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('secondaryBonus', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Bonus Secundar</FormLabel>
                    {errors.secondaryBonus && touched.secondaryBonus ? (
                      <FormErrorMessage>
                        {errors.secondaryBonus}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={
                      errors.welcomeBonusHeader && touched.welcomeBonusHeader
                    }
                    mb={8}
                    variant="floating"
                  >
                    <Field
                      as={Input}
                      name="welcomeBonusHeader"
                      value={values.welcomeBonusHeader}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('welcomeBonusHeader', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Header</FormLabel>
                    {errors.welcomeBonusHeader && touched.welcomeBonusHeader ? (
                      <FormErrorMessage>
                        {errors.welcomeBonusHeader}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.disclaimer && touched.disclaimer}
                    mb={8}
                    variant="floating"
                  >
                    <Field
                      as={Input}
                      name="disclaimer"
                      value={values.disclaimer}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('disclaimer', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Disclaimer</FormLabel>
                    {errors.disclaimer && touched.disclaimer ? (
                      <FormErrorMessage>{errors.disclaimer}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.logoURL && touched.logoURL}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="logoURL"
                      value={values.logoURL}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('logoURL', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>URL Logo</FormLabel>
                    {errors.logoURL && touched.logoURL ? (
                      <FormErrorMessage>{errors.logoURL}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.bonusURL && touched.bonusURL}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="bonusURL"
                      value={values.bonusURL}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('bonusURL', e.target.value);
                      }}
                      placeholder=" "
                    />
                    <FormLabel>URL Bonus</FormLabel>
                    {errors.bonusURL && touched.bonusURL ? (
                      <FormErrorMessage>{errors.bonusURL}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.reviewURL && touched.reviewURL}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="reviewURL"
                      value={values.reviewURL}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('reviewURL', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>URL Review</FormLabel>
                    {errors.reviewURL && touched.reviewURL ? (
                      <FormErrorMessage>{errors.reviewURL}</FormErrorMessage>
                    ) : null}
                  </FormControl>
                  <FormControl
                    isInvalid={errors.rating && touched.rating}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      name="rating"
                      as={Input}
                      value={values.rating}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('rating', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Rating</FormLabel>
                    {errors.rating && touched.rating ? (
                      <FormErrorMessage>{errors.rating}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <Center mt={'4rem'}>
                    <Button
                      isDisabled={!(dirty && isValid)}
                      isLoading={isLoading}
                      minW={'200px'}
                      loadingText="Se actualizeaza"
                      type="submit"
                      colorScheme="green"
                      width="full"
                      textAlign="center"
                    >
                      {add ? 'Adauga' : 'Editeaza'}
                    </Button>
                  </Center>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <br />
    </Container>
  );
}

export default AddOrEditCazino;

import React from 'react';
import { Formik, Form, Field } from 'formik';

import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';

import {
  AsyncCreatableSelect,
  AsyncSelect,
  CreatableSelect,
  Select,
} from 'chakra-react-select';

function ArrayFormFiledSuggestions(props) {
  return (
    <FormControl mb={8} variant="floating" isRequired>
      <Text>{props.label}</Text>
      <Field
        as={Select}
        value={props.value.label ? props.value : null}
        name={props.name}
        placeholder="Alege jocul..."
        onChange={e => {
          console.log(props);
          console.log(e);
          let newGames = [...props.values.games];
          console.log(newGames.length);
          newGames[props.index] = e.label;
          props.setFieldValue('games', newGames);
        }}
        options={Array.from(props.options)}
      ></Field>
    </FormControl>
  );
}
export default ArrayFormFiledSuggestions;

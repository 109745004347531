import React from 'react';
import { useEffect, useState } from 'react';

import { setStoreItems } from '../../features/storeItems/rememberStoreItems';
import { useSelector, useDispatch } from 'react-redux';
import {
  Heading,
  Container,
  Box,
  HStack,
  Spacer,
  SimpleGrid,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import StoreAccordion from './StoreAccordion/StoreAccordion';
import {
  clearState,
  updateState,
  prepareStateForEdit,
} from '../../features/storeItems/addOrEditStoreItems';
import newStoreServices from '../../services/new-store-services';

function Magazin(props) {
  const dispatch = useDispatch();
  const shopState = useSelector(state => state.rememberStoreItems);

  const getStoreItems = async () => {
    console.log('Getting items...');
    try {
      const storeData = await newStoreServices.getAllStoreItems();
      console.log(storeData);
      dispatch(setStoreItems(storeData));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (
      shopState.casinoOffers.products.length === 0 
      //  && shopState.paysafeCards.products.length === 0
    ) {
      getStoreItems();
    } else {
      console.log('Restoring from store');
      console.log(shopState);
    }
  }, []);

  return (
    <Container mt="3rem" minWidth="95%">
      <Heading size="xl">Magazine și Produse</Heading>
      <HStack mt="3">
        <Link to="/magazin-dashboard">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Dashboard Magazin
          </Button>
        </Link>
        <Spacer />
        <Link to="/adauga-item-magazin">
          <Button
            onClick={() => {
              dispatch(clearState());
            }}
            colorScheme={'green'}
          >
            Adauga Item
          </Button>
        </Link>
      </HStack>

      <Accordion my="10" allowToggle>
        {Object.entries(shopState).map((shopCategoryId, index) => {
          console.log(shopCategoryId);

          return (
            <AccordionItem key={shopCategoryId[0]}>
              <AccordionButton>
                <Heading size="lg">{shopCategoryId[1].title}</Heading>
                <Box flex="1" textAlign="left"></Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <StoreAccordion
                  whichIs={shopCategoryId[0]}
                  products={shopCategoryId[1].products}
                />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </Container>
  );
}

export default Magazin;

import { db } from './firebase-users-config';

import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  startAfter,
  limit,
} from 'firebase/firestore';

const usersCollectionRef = collection(db, 'users');
const casinosCollectionRef = collection(db, 'casinoAccounts');

class MainteneanceDataService {
  getCasinoDataForOneUser = async id => {
    let userCasinoCollection = collection(
      usersCollectionRef,
      id,
      'casinoAccounts'
    );

    let casinoAccounts = await getDocs(userCasinoCollection);
    let tmp = casinoAccounts.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return tmp;
  };

  populateCasinoCollectionForOneUser = async (userId, casinos) => {
    for (let casino of casinos) {
      // console.log(casino.id, casino.casinoEmail);
      const emailSubCollectionRef = collection(
        casinosCollectionRef,
        casino.id,
        'emailIndexes'
      );
      const usernameSubCollectionRef = collection(
        casinosCollectionRef,
        casino.id,
        'usernameIndexes'
      );
      await setDoc(doc(emailSubCollectionRef, casino.casinoEmail), {
        userId: userId,
      });
      await setDoc(doc(usernameSubCollectionRef, casino.casinoUsername), {
        userId: userId,
      });
    }
    console.log(`Done for user ${userId}!`);
  };

  startTransferProcess = async id => {
    const casinosMap = await this.getCasinoDataForOneUser(id);
    if (casinosMap.length > 0)
      this.populateCasinoCollectionForOneUser(id, casinosMap);
    else {
      console.log(`${id} does not have associated casinos. Skipping...!`);
    }
  };

  transferCasinoDataForAllUsers = async () => {
    const batchSize = 500; // Adjust the batch size based on your requirements

    let snapshot;
    let batchQuery = query(usersCollectionRef, limit(batchSize));

    do {
      snapshot = await getDocs(batchQuery);

      if (snapshot.size > 0) {
        let lastUserDoc = snapshot.docs[snapshot.docs.length - 1];
        // console.log(lastUserDoc.id);

        snapshot.docs.forEach(async doc => {
          const id = doc.id;
          const casinosMap = await this.getCasinoDataForOneUser(id);

          if (casinosMap.length > 0)
            this.populateCasinoCollectionForOneUser(id, casinosMap);
          else {
            console.log(`${id} does not have associated casinos. Skipping...!`);
          }
        });

        // Prepare the next query by starting after the last document
        batchQuery = query(
          usersCollectionRef,
          startAfter(lastUserDoc),
          limit(batchSize)
        );
      }
    } while (snapshot.size === batchSize);
  };
}

export default new MainteneanceDataService();

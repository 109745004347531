import React from 'react';
import { Formik, Form, Field } from 'formik';

import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';

function FormField(props) {
  return (
    <FormControl
      isInvalid={props.errors[props.name] && props.touched[props.name]}
      mb={8}
      variant="floating"
      isRequired
    >
      <Field
        type={props.type ? props.type : 'text'}
        as={props.as}
        name={props.name}
        value={props.value}
        onChange={e => {
          console.log('On change function', e.target.name, e.target.value);
          props.setFieldValue(e.target.name, e.target.value);
        }}
        placeholder=" "
      />

      <FormLabel>{props.label}</FormLabel>
      {props.errors[props.name] && props.touched[props.name] ? (
        <FormErrorMessage>{props.errors[props.name]}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
}

export default FormField;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  promoCarouselItems: [],
};

const rememberPromoCarouselItemSlice = createSlice({
  name: 'promoCarouselItems',
  initialState,
  reducers: {
    setPromoCarouselItems: (state, { payload }) => {
      state.promoCarouselItems = payload.promoCarouselItems;
    },
    removeItemFromPromoCarousel: (state, { payload }) => {
      state.promoCarouselItems = state.promoCarouselItems.filter(
        element => element.id !== payload.id
      );
    },
    addPromoCarouselItem: (state, { payload }) => {
      state.promoCarouselItems.push(payload.newItem);
      state.promoCarouselItems[state.promoCarouselItems.length - 1].id =
        payload.id;
      state.promoCarouselItems = state.promoCarouselItems.sort(function (a, b) {
        console.log(a);
        return a.isPublished || b.isPublished || a.name.localeCompare(b.name);
      });
    },
    editPromoCarouselItem: (state, { payload }) => {
      const index = state.promoCarouselItems.findIndex(
        item => item.id === payload.id
      );
      state.promoCarouselItems[index] = payload.editedItem;
      state.promoCarouselItems[index].id = payload.id;
      state.promoCarouselItems = state.promoCarouselItems.sort(function (a, b) {
        console.log(a.isPublished);
        return a.isPublished || b.isPublished || a.name.localeCompare(b.name);
      });

      console.log(index);
    },
  },
});

export default rememberPromoCarouselItemSlice.reducer;
export const {
  setPromoCarouselItems,
  removeItemFromPromoCarousel,
  addPromoCarouselItem,
  editPromoCarouselItem,
} = rememberPromoCarouselItemSlice.actions;

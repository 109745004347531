import React, { useState, useEffect } from 'react';
import {
  Heading,
  Container,
  Box,
  Button,
  FormControl,
  Center,
  Input,
  FormLabel,
  FormErrorMessage,
  HStack,
  Spacer,
  Text,
  TableContainer,
  Tbody,
  Tr,
  Td,
  Table,
  Flex,
  IconButton,
  Grid,
  GridItem,
  Thead,
  Th,
  Tooltip,
  Editable,
  EditableInput,
  EditableTextarea,
  EditablePreview,
  useEditableControls,
  ButtonGroup,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';
import _ from 'underscore';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EmailSchema from './EmailSchema';
import PointsSchema from './PointsSchema';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UsersDataService from '../../services/users-services';
import TransactionsDataService from '../../services/transactions-services';
import DeleteModal from '../../components/Modal/Modal';
import {
  SearchIcon,
  CheckCircleIcon,
  NotAllowedIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
} from '@chakra-ui/icons';
import AliasSchema from './AliasSchema';
import IDSchema from './IDSchema';
import { FaSnowflake, FaSun } from 'react-icons/fa';

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
      <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
    </ButtonGroup>
  ) : (
    <Flex justifyContent="center">
      <IconButton size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
    </Flex>
  );
}

function Utilizatori() {
  const [isLoading, setIsLoading] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [submit2IsLoading, setSubmit2IsLoading] = useState(false);
  const [userFullData, setUserFullData] = useState({});
  const [userTransactions, setUserTransactions] = useState([]);
  const [invitedFriendsArray, setInvitedFriendsArray] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentAccount, setCurrentAccount] = useState(-1);
  const [userAccounts, setUserAccounts] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      submitForm({ id: params.id });
      setActiveTab(1);
    }
  }, []);

  const getAccountData = async (accountNo, userAccounts) => {
    let tmpEmails = [];

    setErrorMessage('');
    setInvitedFriendsArray([]);
    setUserFullData({});
    setUserTransactions([]);

    console.log(userAccounts[accountNo]);

    if (Object.keys(userAccounts[accountNo]).length > 0) {
      if (userAccounts[accountNo].invitedUsers !== undefined) {
        for (let id of userAccounts[accountNo].invitedUsers) {
          let auxEmail = await UsersDataService.getUserById(id);
          if (auxEmail.data() !== undefined)
            tmpEmails.push(auxEmail.data().email);
        }
      }

      setUserFullData(userAccounts[accountNo]);
      setInvitedFriendsArray(tmpEmails);
      await getUserTransactions(userAccounts[accountNo].id);
    } else {
      setErrorMessage(
        'Aceasta adresa de mail nu este asociata niciunui utilizator.'
      );
    }
  };

  const submitForm = async (values, resetForm) => {
    setUserAccounts([]);
    setSubmitIsLoading(true);
    setCurrentAccount(-1);
    setUserFullData({});

    let userFullDataAux = [];

    if (values.id !== undefined) {
      let user = await UsersDataService.getUserByID(values.id);
      let userSubCollection = await UsersDataService.getUserSubCollectionById(
        values.id
      );

      let newUser = {
        ...user.data(),
        id: values.id,
        casinoAccounts: userSubCollection.docs.map(d => ({
          id: d.id,
          ...d.data(),
        })),
      };

      userFullDataAux.push(newUser);
    } else {
      userFullDataAux = await UsersDataService.getParticularUser(
        values.email !== undefined ? values.email : values.alias,
        values.email !== undefined ? 'email' : 'alias'
      );
    }

    if (userFullDataAux.length > 0) {
      setUserAccounts(userFullDataAux);
      setCurrentAccount(1);
      await getAccountData(0, userFullDataAux);
      if (!params.id) resetForm({});
    } else {
      setErrorMessage('Nu exista un utilizator cu acest mail.');
    }
    setSubmitIsLoading(false);
  };

  const checkUserIdentity = async () => {
    const id = userFullData.id;
    console.log('checkUserIdentity', id, userFullData.manuallyVerified);

    const verifiedStatus =
      userFullData.manuallyVerified === true ? true : false;
    const bonusValue = userFullData.manuallyVerified === true ? -100 : 100;
    try {
      setIsLoading(true);
      await addTransaction(id, parseInt(bonusValue), 'ACCOUNT-VERIFICATION');
      await UsersDataService.manuallyVerifyUser(id, !verifiedStatus);
      setUserFullData({
        ...userFullData,
        manuallyVerified: !verifiedStatus,
        coinCount: userFullData.coinCount + parseInt(bonusValue),
      });
      let accounts = [...userAccounts];
      let account = { ...userAccounts[currentAccount - 1] };
      account.manuallyVerified = !verifiedStatus;
      accounts[currentAccount - 1] = account;
      setUserAccounts(accounts);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const setAccounStatus = async () => {
    const id = userFullData.id;
    console.log('userFullData', id, userFullData.frozenAccount);

    const verifiedStatus = userFullData.frozenAccount === true ? true : false;
    try {
      setIsLoading(true);

      await UsersDataService.setAccounStatus(id, !verifiedStatus);
      setUserFullData({
        ...userFullData,
        frozenAccount: !verifiedStatus,
      });
      let accounts = [...userAccounts];
      let account = { ...userAccounts[currentAccount - 1] };
      account.frozenAccount = !verifiedStatus;
      accounts[currentAccount - 1] = account;
      setUserAccounts(accounts);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getUserTransactions = async id => {
    console.log('User Transactions', id);
    try {
      const transactions = await TransactionsDataService.getTransactionById(id);
      setUserTransactions(transactions.data().transactions);
    } catch (e) {
      if (String(e).includes('transactions')) {
        toast.warning(`Utilizatorul nu are tranzactii.`);
      } else {
        toast.error(`S-a produs urmatoarea eroare: ${e}`);
      }
    }
  };

  const deleteTransactionsForUser = async id => {
    try {
      await TransactionsDataService.deleteTransaction(id);
      setUserTransactions([]);
      toast.success('Operatiunea s-a realizat cu succes!');
    } catch (e) {
      console.log(e);
      toast.error(`S-a produs urmatoarea eroare: ${e}`);
    }
  };

  const deleteUserAccount = async id => {
    try {
      await UsersDataService.deleteUser(id);
      setErrorMessage('');
      setInvitedFriendsArray([]);
      setUserFullData({});
      setUserTransactions([]);
      toast.success('Operatiunea s-a realizat cu succes!');
    } catch (e) {
      console.log(e);
      toast.error(`S-a produs urmatoarea eroare: ${e}`);
    }
  };

  const addTransaction = async (id, puncteBonus, source) => {
    const finalCoinCount =
      userFullData.coinCount !== undefined
        ? parseInt(userFullData.coinCount) + parseInt(puncteBonus)
        : parseInt(puncteBonus);
    await UsersDataService.addPointsToUser(id, finalCoinCount);
    let accounts = [...userAccounts];
    let account = { ...userAccounts[currentAccount - 1] };
    account.coinCount = finalCoinCount;
    accounts[currentAccount - 1] = account;
    setUserAccounts(accounts);
    const newTransaction = {
      amount: Math.abs(parseInt(puncteBonus)),
      transactionSource: source,
      transactionType: parseInt(puncteBonus) < 0 ? 'CREDIT' : 'DEBIT',
    };
    await TransactionsDataService.addTransaction(newTransaction, id);
    await getUserTransactions(id);
    setUserFullData({ ...userFullData, coinCount: finalCoinCount });
  };

  const submitAddPointsForm = async (values, resetForm) => {
    try {
      setSubmit2IsLoading(true);
      const id = userFullData.id;
      await addTransaction(id, values.puncteBonus, 'DASHBOARD');
      resetForm({});
      setSubmit2IsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Container mt="3rem" minWidth="95%">
        <Heading size="xl"> Cauta Detalii despre un Utilizator</Heading>
        <HStack mt="3">
          <Link to="/magazin-dashboard">
            <Button
              leftIcon={<HiArrowNarrowLeft />}
              colorScheme="green"
              variant="outline"
            >
              Dashboard
            </Button>
          </Link>
          <Spacer />
        </HStack>
      </Container>
      <Container mt="3rem" maxW="container.lg">
        <Box
          borderWidth="1px"
          borderRadius="lg"
          boxShadow="sm"
          my="2rem"
          p="2rem"
          maxW={'900px'}
        >
          <Box p={8}>
            <Tabs variant="enclosed" defaultIndex={activeTab}>
              <TabList>
                <Tab>
                  <Text>Cauta dupa email</Text>
                </Tab>
                <Tab>
                  <Text>Cauta dupa alias</Text>
                </Tab>
                <Tab>
                  <Text>Cauta dupa ID</Text>
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Formik
                    initialValues={{
                      email: '',
                    }}
                    onSubmit={(values, { resetForm }) => {
                      submitForm(values, resetForm);
                    }}
                    validationSchema={EmailSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <FormControl
                            isInvalid={errors.email && touched.email}
                            mb={8}
                            variant="floating"
                            isRequired
                          >
                            <Field
                              name="email"
                              as={Input}
                              value={values.email}
                              onChange={e => {
                                setFieldValue('email', e.target.value);
                              }}
                              placeholder=" "
                            ></Field>
                            <FormLabel>Email utilizator</FormLabel>
                            {errors.email && touched.email ? (
                              <FormErrorMessage>
                                {errors.email}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>

                          <Center mt={'2rem'}>
                            <Button
                              isDisabled={!(dirty && isValid)}
                              isLoading={submitIsLoading}
                              minW={'200px'}
                              loadingText="Se actualizeaza"
                              type="submit"
                              colorScheme="green"
                              width="full"
                              textAlign="center"
                            >
                              Cauta utilizator
                            </Button>
                          </Center>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanel>
                <TabPanel>
                  <Formik
                    initialValues={{
                      alias: '',
                    }}
                    onSubmit={(values, { resetForm }) => {
                      submitForm(values, resetForm);
                    }}
                    validationSchema={AliasSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <FormControl
                            isInvalid={errors.alias && touched.alias}
                            mb={8}
                            variant="floating"
                            isRequired
                          >
                            <Field
                              name="alias"
                              as={Input}
                              value={values.alias}
                              onChange={e => {
                                setFieldValue('alias', e.target.value);
                              }}
                              placeholder=" "
                            ></Field>
                            <FormLabel>Alias utilizator</FormLabel>
                            {errors.alias && touched.alias ? (
                              <FormErrorMessage>
                                {errors.alias}
                              </FormErrorMessage>
                            ) : null}
                          </FormControl>

                          <Center mt={'2rem'}>
                            <Button
                              isDisabled={!(dirty && isValid)}
                              isLoading={submitIsLoading}
                              minW={'200px'}
                              loadingText="Se actualizeaza"
                              type="submit"
                              colorScheme="green"
                              width="full"
                              textAlign="center"
                            >
                              Cauta utilizator
                            </Button>
                          </Center>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanel>
                <TabPanel>
                  <Formik
                    initialValues={{
                      id: '',
                    }}
                    onSubmit={(values, { resetForm }) => {
                      submitForm(values, resetForm);
                    }}
                    validationSchema={IDSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      dirty,
                      isValid,
                      setFieldValue,
                    }) => {
                      return (
                        <Form>
                          <FormControl
                            isInvalid={errors.id && touched.id}
                            mb={8}
                            variant="floating"
                            isRequired
                          >
                            <Field
                              name="id"
                              as={Input}
                              value={values.id}
                              onChange={e => {
                                setFieldValue('id', e.target.value);
                              }}
                              placeholder=" "
                            ></Field>
                            <FormLabel>ID utilizator</FormLabel>
                            {errors.id && touched.id ? (
                              <FormErrorMessage>{errors.id}</FormErrorMessage>
                            ) : null}
                          </FormControl>

                          <Center mt={'2rem'}>
                            <Button
                              isDisabled={!(dirty && isValid)}
                              isLoading={submitIsLoading}
                              minW={'200px'}
                              loadingText="Se actualizeaza"
                              type="submit"
                              colorScheme="green"
                              width="full"
                              textAlign="center"
                            >
                              Cauta utilizator
                            </Button>
                          </Center>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <Heading mt={8} color="red" size="sm">
              {errorMessage}
            </Heading>
          </Box>
          <Box my={3} />

          <Box my={3} />

          {showSpinner && <Spinner />}

          {Object.keys(userFullData).length > 0 && (
            <>
              <Heading size="lg">Detalii user</Heading>{' '}
              <Heading size="sm">
                Exista {userAccounts.length}{' '}
                {userAccounts.length === 1
                  ? 'cont asociat'
                  : 'conturi asociate'}{' '}
                cu acest email.
              </Heading>{' '}
              <Box my={3} />
              {[...Array(userAccounts.length)].map((e, i) => {
                return (
                  <Button
                    key={`button-${i}`}
                    size="sm"
                    isActive={i + 1 === currentAccount ? true : false}
                    onClick={async () => {
                      return (
                        setCurrentAccount(i + 1),
                        setShowSpinner(true),
                        await getAccountData(i, userAccounts),
                        setShowSpinner(false)
                      );
                    }}
                  >
                    Cont {i + 1}
                  </Button>
                );
              })}
              <TableContainer py={8}>
                <Table size="sm" colorScheme="blackAlpha" variant="striped">
                  <Tbody>
                    <Tr>
                      <Td>
                        <b>ID:</b>{' '}
                      </Td>
                      <Td>{userFullData.id}</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Email:</b>{' '}
                      </Td>

                      <Td>
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.email}
                          onSubmit={async value =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              'email',
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Alias:</b>{' '}
                      </Td>{' '}
                      <Td>
                        {' '}
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.alias}
                          onSubmit={async value =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              'alias',
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <b>Prenume:</b>{' '}
                      </Td>{' '}
                      <Td>
                        {' '}
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.firstName}
                          onSubmit={async value =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              'firstName',
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Nume:</b>{' '}
                      </Td>
                      <Td>
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.lastName}
                          onSubmit={async value =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              'lastName',
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {' '}
                        <b>Tel:</b>{' '}
                      </Td>
                      <Td>
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.phoneNumber}
                          onSubmit={async value =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              'phoneNumber',
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableInput} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {' '}
                        <b>User verificat manual</b>{' '}
                      </Td>
                      <Td>
                        {userFullData.manuallyVerified !== undefined ? (
                          userFullData.manuallyVerified === true ? (
                            <HStack>
                              <Text color={'green'}>DA</Text>
                              <Spacer />
                              <Tooltip label="Invalidează identitatea">
                                <IconButton
                                  isLoading={isLoading}
                                  size="sm"
                                  colorScheme="red"
                                  aria-label="Invalidează identitatea"
                                  onClick={checkUserIdentity}
                                  icon={<NotAllowedIcon />}
                                />
                              </Tooltip>
                            </HStack>
                          ) : (
                            <HStack>
                              <Text color={'red'}>NU</Text>
                              <Spacer />
                              <Tooltip label="Validează identitatea">
                                <IconButton
                                  isLoading={isLoading}
                                  size="sm"
                                  colorScheme="green"
                                  aria-label="Validează identitatea"
                                  onClick={checkUserIdentity}
                                  icon={<CheckCircleIcon />}
                                />
                              </Tooltip>
                            </HStack>
                          )
                        ) : (
                          <HStack>
                            {' '}
                            <Text color={'red'}>NU</Text>
                            <Spacer />
                            <Tooltip label="Validează identitatea">
                              <IconButton
                                isLoading={isLoading}
                                size="sm"
                                aria-label="Validează identitatea"
                                colorScheme="green"
                                onClick={checkUserIdentity}
                                icon={<CheckCircleIcon />}
                              />
                            </Tooltip>
                          </HStack>
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {' '}
                        <b>Cont inghetat</b>{' '}
                      </Td>
                      <Td>
                        {userFullData.frozenAccount !== undefined ? (
                          userFullData.frozenAccount === true ? (
                            <HStack>
                              <Text color={'red'}>DA</Text>
                              <Spacer />
                              <Tooltip label="Dezgheata contul">
                                <IconButton
                                  isLoading={isLoading}
                                  size="sm"
                                  colorScheme="green"
                                  aria-label="Dezgheata contul"
                                  onClick={setAccounStatus}
                                  icon={<FaSun />}
                                />
                              </Tooltip>
                            </HStack>
                          ) : (
                            <HStack>
                              <Text color={'green'}>NU</Text>
                              <Spacer />
                              <Tooltip label="Ingheata contul">
                                <IconButton
                                  isLoading={isLoading}
                                  size="sm"
                                  colorScheme="red"
                                  aria-label="Ingheata contul"
                                  onClick={setAccounStatus}
                                  icon={<FaSnowflake />}
                                />
                              </Tooltip>
                            </HStack>
                          )
                        ) : (
                          <HStack>
                            {' '}
                            <Text color={'green'}>NU</Text>
                            <Spacer />
                            <Tooltip label="Ingheata contul">
                              <IconButton
                                isLoading={isLoading}
                                size="sm"
                                aria-label="Ingheata contul"
                                colorScheme="red"
                                onClick={setAccounStatus}
                                icon={<FaSnowflake />}
                              />
                            </Tooltip>
                          </HStack>
                        )}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {' '}
                        <b>Numar puncte:</b>{' '}
                      </Td>
                      <Td>{userFullData.coinCount}</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Ultimul spin la roata</b>
                      </Td>
                      <Td>
                        {userFullData.lastSpinned &&
                          new Date(
                            userFullData.lastSpinned.toMillis()
                          ).toLocaleDateString('ro-RO')}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Ultimul streak inregistrat</b>
                      </Td>
                      <Td>
                        {userFullData.lastStreakClaim &&
                          new Date(
                            userFullData.lastStreakClaim.toMillis()
                          ).toLocaleDateString('ro-RO')}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Prieteni Invitati:</b>{' '}
                      </Td>
                      <Td>
                        {userFullData.invitedUsers !== undefined
                          ? userFullData.invitedUsers.length
                          : 0}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>-</Td>
                      <Td>
                        {' '}
                        {invitedFriendsArray.map(e => (
                          <Text key={e}>{e}</Text>
                        ))}
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Cod Referral:</b>{' '}
                      </Td>{' '}
                      <Td>{userFullData.referralCode}</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <b>Daly Streak:</b>{' '}
                      </Td>
                      <Td>{userFullData.streakCount}</Td>
                    </Tr>

                    <Tr>
                      <Td>
                        <b>Cazinouri asociate:</b>{' '}
                      </Td>
                      <Td>
                        {userFullData.casinoAccounts !== undefined
                          ? userFullData.casinoAccounts.length
                          : 0}
                      </Td>
                    </Tr>

                    {userFullData.casinoAccounts !== undefined &&
                      userFullData.casinoAccounts.length !== 0 &&
                      userFullData.casinoAccounts.map(casino => (
                        <Tr key={casino.id}>
                          <Td></Td>
                          <Td>
                            <Accordion
                              allowToggle
                              allowMultiple={false}
                              width={'100%'}
                            >
                              <AccordionItem border={0}>
                                <AccordionButton>
                                  <b>{casino.id}</b>
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                  <b>Email: </b>
                                  {casino.casinoEmail}
                                  <br />
                                  <b>Alias:</b> {casino.casinoUsername}
                                  <br />
                                  <b>Creat prin Cazino365:</b>{' '}
                                  {casino.accountCreatedViaCazino365
                                    ? 'DA'
                                    : 'NU'}
                                  <br />
                                  <b>Identitate verificata:</b>{' '}
                                  {casino.identityVerified ? 'DA' : 'NU'} <br />
                                  <b>Verificat de noi:</b>{' '}
                                  {casino.verifiedByUs ? 'DA' : 'NU'} <br />
                                  <b>Deposit efectuat:</b>{' '}
                                  {casino.firstDepositCompleted ? 'DA' : 'NU'}{' '}
                                  <br />
                                  <b>Ultimul depozit</b> {casino.lastDeposit}{' '}
                                  <br />
                                  <b>Ultimul depozit</b>{' '}
                                  {new Date(
                                    casino.dateOfLastDeposit.seconds * 1000 +
                                      casino.dateOfLastDeposit.nanoseconds /
                                        1000000
                                  ).toLocaleDateString('ro-RO')}
                                  <br />
                                  <b>Deposit total:</b> {casino.totalDeposits}{' '}
                                  <br />
                                  <b>Pierderi</b> {casino.losses ? 'DA' : 'NU'}{' '}
                                  <br />
                                  <b>Rulaj</b> {casino.rulaj} <br />
                                  <b>Scor VIP{}:</b> {casino.vipScore}
                                </AccordionPanel>
                              </AccordionItem>
                            </Accordion>
                          </Td>
                        </Tr>
                      ))}

                    <Tr>
                      <Td>
                        <b>Notificari Personale:</b>{' '}
                      </Td>
                      <Td> {userFullData.personalNotificationsIsEnabled}</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        {' '}
                        <b>Notificari Generale:</b>{' '}
                      </Td>
                      <Td>{userFullData.generalNotificationsIsEnabled}</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Notificari Evenimente:</b>{' '}
                      </Td>
                      <Td>{userFullData.eventsNotificationsIsEnabled}</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Aprobare Marketing:</b>{' '}
                      </Td>
                      <Td>{userFullData.marketingApproval}</Td>
                    </Tr>
                    <Tr>
                      <Td>
                        <b>Notițe:</b>{' '}
                      </Td>
                      <Td>
                        <Editable
                          textAlign="center"
                          isPreviewFocusable={false}
                          defaultValue={userFullData.notes}
                          onSubmit={async value =>
                            await UsersDataService.updateFieldForUser(
                              userFullData.id,
                              'notes',
                              value
                            )
                          }
                        >
                          <HStack>
                            <EditablePreview />
                            {/* Here is the custom input */}
                            <Input as={EditableTextarea} />
                            <Spacer />
                            <EditableControls />
                          </HStack>
                        </Editable>
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
              <Center>
                <DeleteModal
                  deleteFunction={deleteUserAccount}
                  id={userFullData.id}
                  explanation={'cont'}
                />
              </Center>
              <Heading mt={8} size="lg">
                Tranzactii user
              </Heading>{' '}
              <TableContainer py={8}>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Data tranzactie</Th>
                      <Th>Valoare tranzactie</Th>
                      <Th>Sursa tranzactie</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {userTransactions.length > 0 &&
                      userTransactions
                        .slice(0, 500)
                        .reverse()
                        .map((element, i) => {
                          return (
                            <Tr key={i}>
                              <Td>
                                {new Date(
                                  element.timestamp.toMillis()
                                ).toLocaleDateString('ro-RO')}{' '}
                                <br />
                                {new Date(
                                  element.timestamp.toMillis()
                                ).toLocaleTimeString()}
                              </Td>
                              <Td>
                                {element.transactionType === 'CREDIT' && '-'}
                                {element.amount}
                              </Td>
                              <Td>{element.transactionSource}</Td>
                            </Tr>
                          );
                        })}
                  </Tbody>
                </Table>
              </TableContainer>
              {/* <Button
                isLoading={deleteTransactionsIsLoading}
                loadingText={`Se șterg tranzactiile pentru user-ul ${userFullData.id}`}
                type="submit"
                colorScheme="red"
                width="full"
                textAlign="center"
                onClick={() => {
                  deleteTransactionsForUser(userFullData.id);
                }}
              >
                Șterge tranzactii
              </Button> */}
              <DeleteModal
                deleteFunction={deleteTransactionsForUser}
                id={userFullData.id}
                explanation={'tranzactii'}
              />
              <Box p={8}>
                <Formik
                  initialValues={{
                    puncteBonus: '',
                  }}
                  onSubmit={(values, { resetForm }) => {
                    submitAddPointsForm(values, resetForm);
                  }}
                  validationSchema={PointsSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    dirty,
                    isValid,
                    resetForm,
                    setFieldValue,
                  }) => {
                    return (
                      <Form>
                        <Grid templateColumns="repeat(3, 1fr)">
                          <GridItem colSpan={2}>
                            {' '}
                            <FormControl
                              isInvalid={
                                errors.puncteBonus && touched.puncteBonus
                              }
                              variant="floating"
                              isRequired
                            >
                              <Field
                                name="puncteBonus"
                                as={Input}
                                value={values.puncteBonus}
                                onChange={e => {
                                  setFieldValue('puncteBonus', e.target.value);
                                }}
                                placeholder=" "
                              ></Field>
                              <FormLabel>
                                Adauga puncte pentru utilizator
                              </FormLabel>
                              {errors.puncteBonus && touched.puncteBonus ? (
                                <FormErrorMessage>
                                  {errors.puncteBonus}
                                </FormErrorMessage>
                              ) : null}
                            </FormControl>
                          </GridItem>
                          <GridItem colSpan={1}>
                            {' '}
                            <Button
                              isDisabled={!(dirty && isValid)}
                              isLoading={submit2IsLoading}
                              loadingText="Se actualizeaza"
                              type="submit"
                              colorScheme="purple"
                              width="full"
                              textAlign="center"
                              //   onClick={() => {
                              //     resetForm();
                              //   }}
                            >
                              Adauga puncte
                            </Button>
                          </GridItem>
                        </Grid>
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </>
          )}
        </Box>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <br />
      </Container>
    </>
  );
}

export default Utilizatori;

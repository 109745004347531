import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const mapSchema = Yup.object().shape({
  fromRank: Yup.number()
    .positive('Rank-ul de incepere este un numar pozitiv')
    .required('Rank-ul de incepere este necesar')
    .typeError('Rank-ul de incepere este un numar'),
  toRank: Yup.number()
    .positive('Rank-ul de incepere este un numar pozitiv')
    .required('Rank-ul de final este necesar')
    .typeError('Rank-ul de final este un numar'),
  image: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul imaginii este necesar'),
  name: Yup.string()
    .required('Numele premiului este necesar')
    .typeError('Numele premiului este un sir'),
});

const CreateRuffleValidationSchema = Yup.object().shape({
  imageURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul imaginii este necesar'),
  prizes: Yup.array()
    .of(mapSchema)
    .required('Premiile sunt necesare')
    .min(1, 'Trebuie sa existe macar un premiu')
    .test(
      'consecutive-ranks',
      'Rank-urile trebuie sa fie consecutive',
      function (value) {
        if (!value) return true; // If the value is not defined, return true (other validations will catch this)
        for (let i = 0; i < value.length - 1; i++) {
          if (value[i].toRank + 1 !== value[i + 1].fromRank) {
            return false;
          }
        }
        return true;
      }
    ),
  ticketPrice: Yup.number()
    .typeError('Pretul biletului trebuie sa fie un numar')
    .required('Pretul biletului este necesar'),
  endDate: Yup.date().required('Data finalizarii tombolei este necesara'),
  extractionDate: Yup.date().required('Data extragerii este necesara'),
  mainPageImageURL: Yup.string()
  .url('Acest camp trebuie sa fie un URL valid')
  .required('URL-ul imaginii este necesar'),
});

export default CreateRuffleValidationSchema;

import { db } from './firebase-users-config';

import {
  collection,
  getDocs,
  query,
  addDoc,
  doc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  deleteDoc,
} from 'firebase/firestore';

const StoreCollectionRef = collection(db, 'newShop');

class NewStoreDataService {
  getAllStoreItems = async () => {
    let data = await getDocs(query(StoreCollectionRef));

    let storeItems = [];
    let finalDictionary = {};

    for (let document of data.docs) {
      // Removing paysafe cards from store
      if (document.id !== 'paysafeCards') {
        let oneStoreItem = { title: document.data().title, id: document.id };
        storeItems.push(oneStoreItem);
      }
    }

    for (let store of storeItems) {
      const productsSubCollection = collection(
        db,
        'newShop',
        store.id,
        'products'
      );
      const products = await getDocs(productsSubCollection);

      finalDictionary[store.id] = {
        ...store,
        products: products.docs.map(d => ({
          id: d.id,
          ...d.data(),
        })),
      };
    }
    return finalDictionary;
  };

  addShopItem = async (newShopItem, id) => {
    console.log(newShopItem, id);
    const productsSubCollection = collection(db, 'newShop', id, 'products');
    return await addDoc(productsSubCollection, newShopItem);
  };

  editShopItem = async (newShopItem, id, productId) => {
    console.log(newShopItem, id, productId);
    const productDoc = doc(db, 'newShop', id, 'products', productId);
    return await updateDoc(productDoc, newShopItem, { merge: true });
  };

  deleteStoreItem = async (id, productId) => {
    console.log(id, productId);
    const productDoc = doc(db, 'newShop', id, 'products', productId);
    return await deleteDoc(productDoc);
  };
}

export default new NewStoreDataService();

import { db } from './firebase-users-config';

import {
  collection,
  getDocs,
  query,
  doc,
  updateDoc,
  arrayRemove,
  setDoc,
  getDoc,
  deleteDoc,
  orderBy,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';

const TombolaCollectionRef = collection(db, 'tombole');
const SpecialEventsRef = collection(db, 'specialEvents');

class TombolaDataService {
  tombolaName = 'tombola1';
  specialEventName = 'tombola';

  getAllTickets = () => {
    return getDocs(
      query(collection(TombolaCollectionRef, this.tombolaName, 'tickets'))
    );
  };

  getAllWinners = () => {
    return getDocs(
      query(
        collection(TombolaCollectionRef, this.tombolaName, 'winners'),
        orderBy('rank')
      )
    );
  };

  getTombolaInfo = () => {
    const tombolaDoc = doc(db, 'tombole', this.tombolaName);
    return getDoc(tombolaDoc);
  };

  removeWinners = async () => {
    let winnerCollection = collection(
      TombolaCollectionRef,
      this.tombolaName,
      'winners'
    );

    const toDelete = await getDocs(winnerCollection);

    return toDelete.forEach(item => {
      const id = item.id;
      deleteDoc(doc(db, 'tombole', this.tombolaName, 'winners', id));
    });
  };

  storeWinners = async (users, tickets, emails) => {
    let winnerCollection = collection(
      TombolaCollectionRef,
      this.tombolaName,
      'winners'
    );

    for (let id in users) {
      await setDoc(doc(winnerCollection, String(id).padStart(5, '0')), {
        userId: users[id],
        ticketId: tickets[id],
        userEmail: emails[id],
        rank: parseInt(id),
      });
    }

    return 'Done!';
  };

  validateWinner = async (ticketSeries, rank) => {
    console.log(ticketSeries);

    let tombolaDoc = doc(db, 'tombole', this.tombolaName);

    await setDoc(
      tombolaDoc,
      { extractionDate: Timestamp.fromMillis(Date.now()) },
      { merge: true }
    );

    let winnerDoc = doc(
      db,
      'tombole',
      this.tombolaName,
      'tickets',
      ticketSeries
    );
    return setDoc(
      winnerDoc,
      { prizeIndex: parseInt(rank), isWinner: true },
      { merge: true }
    );
  };

  createNewRuffle = async newRuffleData => {
    console.log(newRuffleData);
    let finalRuffleData = {
      ...newRuffleData,
      endDate: Timestamp.fromMillis(new Date(newRuffleData['endDate'])),
      extractionDate: Timestamp.fromMillis(
        new Date(newRuffleData['extractionDate'])
      ),
    };
    let tombolaDoc = doc(db, 'tombole', this.tombolaName);
    return await setDoc(tombolaDoc, finalRuffleData);
  };

  setSpecialEvent = async specialEventData => {
    let specialEventDoc = doc(db, 'specialEvents', this.specialEventName);
    return await setDoc(specialEventDoc, specialEventData);
  };

  removeAllTickets = async () => {
    let ticketsCollection = collection(
      TombolaCollectionRef,
      this.tombolaName,
      'tickets'
    );

    const toDelete = await getDocs(ticketsCollection);
    const batchSize = 300; // Firestore batch limit
    let batch = writeBatch(db);
    let batchCounter = 0;

    toDelete.forEach((item, index) => {
      const docRef = doc(db, 'tombole', this.tombolaName, 'tickets', item.id);
      batch.delete(docRef);
      batchCounter++;

      if (batchCounter === batchSize) {
        batch.commit();
        batch = writeBatch(db);
        batchCounter = 0;
      }
    });

    // Commit any remaining operations in the last batch
    if (batchCounter > 0) {
      await batch.commit();
    }
  };

  addDummyDocs = async () => {
    const ticketsCollection = collection(
      TombolaCollectionRef,
      this.tombolaName,
      'tickets'
    );
    const batchSize = 500; // Firestore batch limit
    let batch = writeBatch(db);
    let batchCounter = 0;

    for (let i = 0; i < 1000; i++) {
      const docRef = doc(ticketsCollection); // Automatically generate an ID
      const dummyData = {
        // Add your dummy data fields here
        name: `Dummy Ticket ${i + 1}`,
        description: `This is dummy ticket number ${i + 1}`,
        createdAt: new Date(),
        // Add more fields as needed
      };

      batch.set(docRef, dummyData);
      batchCounter++;

      if (batchCounter === batchSize) {
        await batch.commit();
        batch = writeBatch(db);
        batchCounter = 0;
      }
    }

    // Commit any remaining operations in the last batch
    if (batchCounter > 0) {
      await batch.commit();
    }
  };
}
export default new TombolaDataService();

import React from 'react';
import {
  Heading,
  Container,
  Box,
  HStack,
  Spacer,
  SimpleGrid,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Center,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiArrowNarrowLeft } from 'react-icons/hi';

function StoreDashboard() {
  return (
    <Container mt="3rem" minWidth="95%">
      <Heading size="xl">Panou de Comanda Magazin</Heading>
      <HStack mt="3">
        <Link to="/admin-dashboard">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Dashboard
          </Button>
        </Link>
        <Spacer />
      </HStack>
      <SimpleGrid mt="10" spacing={8} columns={{ sm: 1, md: 1, lg: 2, xl: 2 }}>
        <Link width="100%" to="/magazin">
          <Box p={5} shadow="md" borderWidth="1px">
            {' '}
            <Center>
              <Heading size="md">Magazine și Produse</Heading>
            </Center>
          </Box>
        </Link>
        <Link width="100%" to="/comenzi">
          <Box p={5} shadow="md" borderWidth="1px">
            {' '}
            <Center>
              <Heading size="md">Comenzi</Heading>
            </Center>
          </Box>
        </Link>
      </SimpleGrid>
      <Box h={7} />
      <Link width="100%" to="/utilizatori">
        <Box p={5} shadow="md" borderWidth="1px">
          {' '}
          <Center>
            <Heading size="md">Utilizatori</Heading>
          </Center>
        </Box>
      </Link>
    </Container>
  );
}

export default StoreDashboard;

import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Heading,
} from '@chakra-ui/react';
import { GiSaveArrow } from 'react-icons/gi';

const DeleteModal = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button isLoading={props.isLoading} colorScheme="gray" onClick={onOpen}>
        Exporta DB
      </Button>

      <Modal
        isLoading={props.isLoading}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Esti sigur?</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            Dacă vei continua să exporți baza de date se vor efectua mii de
            citiri care pot genera costuri suplimentare. Dorești totuși să
            continui? <br />
          </ModalBody>

          <ModalFooter>
            <Button
              variant="outline"
              colorScheme="gray"
              mr={3}
              onClick={onClose}
            >
              Renunță
            </Button>
            <Button
              id={props.id}
              rightIcon={<GiSaveArrow />}
              colorScheme="purple"
              onClick={e => {
                props.confirmFunction();
                onClose();
              }}
            >
              Da, exportă
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteModal;

import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Formik, Form, Field } from 'formik';
//DATA SERVICE
import NewStoreDataService from '../../services/new-store-services';
// REDUX
import { useSelector, useDispatch } from 'react-redux';
import {
  addShopItem,
  editShopItem,
} from '../../features/storeItems/rememberStoreItems';

import {
  clearState,
  updateState,
  prepareStateForEdit,
} from '../../features/storeItems/addOrEditStoreItems';
// VALIDATION
import AddOrEditSchema from './AddOrEditShopValidationSchema';
//Chakra.ui
import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Select,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react';
//ICONS
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';

function AddOrEditShopItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const add = useSelector(state => state.addOrEditStoreItems.add);
  const index = useSelector(state => state.addOrEditStoreItems.index);
  const id = useSelector(state => state.addOrEditStoreItems.id);
  const productId = useSelector(state => state.addOrEditStoreItems.productId);
  const name = useSelector(state => state.addOrEditStoreItems.name);
  const description = useSelector(
    state => state.addOrEditStoreItems.description
  );
  const deliveryDate = useSelector(
    state => state.addOrEditStoreItems.deliveryDate
  );
  const image = useSelector(state => state.addOrEditStoreItems.image);
  const price = useSelector(state => state.addOrEditStoreItems.price);
  const stock = useSelector(state => state.addOrEditStoreItems.stock);
  const type = useSelector(state => state.addOrEditStoreItems.type);
  const redirectURL = useSelector(
    state => state.addOrEditStoreItems.redirectURL
  );

  const generalRememberStore = useSelector(state => state.rememberStoreItems);

  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async values => {
    setIsLoading(true);

    let defaultURL = {
      LASVEGAS: 'https://cazino365.ro/LasVegasApp',
      SUPERBET: 'https://,cazino365.ro/SuperbetSpecial',
      BETANO: 'https://cazino365.ro/BetanoSpecial',
      888: 'https://cazino365.ro/888free88ron',
      VLADCAZINO: 'https://cazino365.ro/links/vlad',
      UNIBET: 'https://cazino365.ro/links/unibet',
      MAXBET: 'https://cazino365.ro/links/maxbet',
      NETBET: 'https://cazino365.ro/links/netbet500fs',
      GETSBET: 'https://cazino365.ro/GetsbetSpecial',
      SPORTINGBET: 'https://cazino365.ro/links/sportingbet',
      MRBIT: 'https://cazino365.ro/links/mrbit',
      WINMASTERS: 'https://cazino365.ro/links/winmasters',
      POKERSTARS: 'https://cazino365.ro/links/PokerStars50FS',
      MOZZARTBET: 'https://cazino365.ro/MozzartSpecial',
      PLAYER: 'https://cazino365.ro/playerfree',
      MILLION: 'https://cazino365.ro/links/MillionSpecial',
    };

    const newShopItem = {
      price: parseInt(values.price),
      description: values.description.trim(),
      name: values.name.trim(),
      image: values.image.trim(),
      stock: parseInt(values.stock),
      type: values.id === 'paysafeCards' ? 'PAYSAFE' : values.type.trim(),
      redirectURL: values.redirectURL.trim(),
      deliveryDate: values.deliveryDate.trim(),
    };

    console.log(newShopItem);

    try {
      if (add === true) {
        await NewStoreDataService.addShopItem(newShopItem, values.id);
        dispatch(addShopItem({ product: newShopItem, id: values.id }));
        dispatch(clearState());
      } else {
        console.log(index);
        await NewStoreDataService.editShopItem(
          newShopItem,
          values.id,
          productId.value
        );
        dispatch(
          editShopItem({ product: newShopItem, id: values.id, index: index })
        );
        dispatch(clearState());
      }

      toast.success('Operatiunea s-a realizat cu succes!');
      setIsLoading(false);
      navigate('/magazin');
    } catch (e) {
      console.log(e);
      toast.error('S-a produs o eraore. Incearca din nou.');
      setIsLoading(false);
    }
  };

  return (
    <Container mt="3rem" maxW="container.lg">
      <VStack>
        <Link to="/magazin">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
            onClick={() => {
              dispatch(clearState());
            }}
          >
            Back
          </Button>
        </Link>
        <Spacer />
        <Heading textAlign={'center'} size="xl" py="3" mb="5">
          {add ? 'Adauga un item in magazin' : 'Editeaza item-ul din magazin'}
        </Heading>
      </VStack>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="sm"
        my="2rem"
        p="2rem"
        maxW={'900px'}
      >
        <Box p={8}>
          <Formik
            initialValues={{
              id: id.value,
              price: price.value,
              description: description.value,
              image: image.value,
              name: name.value,
              stock: stock.value,
              type: type.value,
              redirectURL: redirectURL.value,
              deliveryDate: deliveryDate.value,
            }}
            onSubmit={values => {
              submitForm(values);
            }}
            validationSchema={AddOrEditSchema}
          >
            {({ values, errors, touched, dirty, isValid, setFieldValue }) => {
              return (
                <Form>
                  <FormControl
                    isInvalid={errors.id && touched.id}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Select}
                      name="id"
                      isDisabled={add ? false : true}
                      value={values.id}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('id', e.target.value);
                      }}
                      placeholder=" "
                    >
                      {Object.entries(generalRememberStore).map(
                        (objectEntry, index) => {
                          return (
                            <option key={objectEntry[0]} value={objectEntry[0]}>
                              {objectEntry[0]}
                            </option>
                          );
                        }
                      )}
                    </Field>
                    <FormLabel>ID magazin</FormLabel>
                    {errors.id && touched.id ? (
                      <FormErrorMessage>{errors.id}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.name && touched.name}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="name"
                      value={values.name}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('name', e.target.value);
                      }}
                      placeholder=" "
                    />
                    <FormLabel>Numele produsului</FormLabel>
                    {errors.name && touched.name ? (
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.description && touched.description}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="description"
                      value={values.description}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('description', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Descrierea produsului</FormLabel>
                    {errors.description && touched.description ? (
                      <FormErrorMessage>{errors.description}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.price && touched.price}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="price"
                      value={values.price}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('price', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Pretul produsului</FormLabel>
                    {errors.price && touched.price ? (
                      <FormErrorMessage>{errors.price}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.image && touched.image}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="image"
                      value={values.image}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('image', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>URL Imagine Produs</FormLabel>
                    {errors.image && touched.image ? (
                      <FormErrorMessage>{errors.image}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.stock && touched.stock}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      name="stock"
                      as={Input}
                      value={values.stock}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('stock', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Stoc produs</FormLabel>
                    {errors.stock && touched.stock ? (
                      <FormErrorMessage>{errors.stock}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  {values.id === 'casinoOffers' && (
                    <>
                      <FormControl
                        isInvalid={errors.type && touched.type}
                        mb={8}
                        variant="floating"
                        isRequired
                      >
                        <Field
                          as={Select}
                          name="type"
                          isDisabled={add ? false : true}
                          value={values.type}
                          onChange={e => {
                            dispatch(
                              updateState({
                                value: e.target.value,
                                name: e.target.name,
                              })
                            );
                            setFieldValue('type', e.target.value);
                          }}
                          placeholder=" "
                        >
                          <option value="LASVEGAS">LASVEGAS</option>
                          <option value="SUPERBET">SUPERBET</option>
                          <option value="BETANO">BETANO</option>
                          <option value="888">888</option>
                          <option value="VLADCAZINO">VLADCAZINO</option>
                          <option value="UNIBET">UNIBET</option>
                          <option value="MAXBET">MAXBET</option>
                          <option value="NETBET">NETBET</option>
                          <option value="GETSBET">GETSBET</option>
                          <option value="SPORTINGBET">SPORTINGBET</option>
                          <option value="MRBIT">MRBIT</option>
                          <option value="WINMASTERS">WINMASTERS</option>
                          <option value="POKERSTARS">POKERSTARS</option>
                          <option value="MOZZARTBET">MOZZARTBET</option>
                          <option value="PLAYER">PLAYER</option>
                          <option value="MILLION">MILLION</option>
                        </Field>
                        <FormLabel>Nume Cazino</FormLabel>
                        {errors.type && touched.type ? (
                          <FormErrorMessage>{errors.type}</FormErrorMessage>
                        ) : null}
                      </FormControl>

                      <FormControl
                        isInvalid={errors.redirectURL && touched.redirectURL}
                        mb={8}
                        variant="floating"
                        isRequired
                      >
                        <Field
                          as={Input}
                          name="redirectURL"
                          value={values.redirectURL}
                          onChange={e => {
                            dispatch(
                              updateState({
                                value: e.target.value,
                                name: e.target.name,
                              })
                            );
                            setFieldValue('redirectURL', e.target.value);
                          }}
                          placeholder=" "
                        />

                        <FormLabel>URL de Redirect</FormLabel>
                        {errors.redirectURL && touched.redirectURL ? (
                          <FormErrorMessage>
                            {errors.redirectURL}
                          </FormErrorMessage>
                        ) : null}
                      </FormControl>
                    </>
                  )}
                  <FormControl
                    isInvalid={errors.deliveryDate && touched.deliveryDate}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="description"
                      value={values.deliveryDate}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('deliveryDate', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Data de livrare a produsului</FormLabel>
                    {errors.deliveryDate && touched.deliveryDate ? (
                      <FormErrorMessage>{errors.deliveryDate}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <Center mt={'4rem'}>
                    <Button
                      isDisabled={!(dirty && isValid)}
                      isLoading={isLoading}
                      minW={'200px'}
                      loadingText="Se actualizeaza"
                      type="submit"
                      colorScheme="green"
                      width="full"
                      textAlign="center"
                    >
                      {add ? 'Adauga' : 'Editeaza'}
                    </Button>
                  </Center>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <br />
    </Container>
  );
}

export default AddOrEditShopItem;

import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const AddOrEditSchema = Yup.object().shape({
  id: Yup.string().required('Id-ul este necesar'),
  name: Yup.string().required('Numele produsului este un câmp obligatoriu.'),
  description: Yup.string().required(
    'Descrierea produsului este un câmp obligatoriu.'
  ),
  deliveryDate: Yup.string().required(
    'Data de livrare a produsului este un câmp obligatoriu.'
  ),
  image: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .required('URL-ul produsului este un câmp obligatoriu.'),
  price: Yup.number()
    .typeError('Pretul trebuie sa fie un numar')
    .min(0, 'Pretul trebuie sa fie mai mare decat 0')
    .required('Pretul produsului este un câmp obligatoriu.'),
  stock: Yup.number()
    .typeError('Stocul trebuie sa fie un numar')
    .min(0, 'Stocul trebuie sa fie mai mare decat 0')
    .required('Stocul produsului este un câmp obligatoriu.'),
  type: Yup.string().test({
    message: 'Tipul cazinoului este obligatoriu',
    test: (productType, context) => {
      console.log(context.parent);
      if (context.parent.id === 'casinoOffers' && productType === '')
        return false;
      return true;
    },
  }),
  redirectURL: Yup.string()
    .url('Acest camp trebuie sa fie un URL valid')
    .test({
      message: 'URL-ul de redirect este un câmp obligatoriu.',
      test: (redirectURL, context) => {
        console.log(context.parent);
        if (context.parent.id === 'casinoOffers' && redirectURL === '')
          return false;
        return true;
      },
    }),
});

export default AddOrEditSchema;

import { db } from './firebase-users-config';

import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  setDoc,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
} from 'firebase/firestore';

import { auth } from './firebase-admin';

const usersCollectionRef = collection(db, 'users');

class UsersDataService {
  getAllUsers = () => {
    return getDocs(query(usersCollectionRef, orderBy('email')));
  };
  // modifyAllUsersCoinCount = async () => {
  //   try {
  //     let userDocs = await getDocs(query(usersCollectionRef));
  //     for (let document of userDocs.docs) {
  //       const id = document.id;

  //       let newDocValues = {
  //         ...document.data(),
  //         coinCount: document.data().coinCount
  //           ? parseInt(document.data().coinCount) + 100
  //           : 100,
  //       };
  //       const docRef = doc(db, 'users', id);
  //       await setDoc(docRef, newDocValues, { merge: true });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  getUserByID = async id => {
    const docRef = doc(db, 'users', id);
    return await getDoc(docRef);
  };

  getUserSubCollectionById = async id => {
    const subCollectionRef = collection(db, 'users', id, 'casinoAccounts');
    return await getDocs(subCollectionRef);
  };

  getParticularUser = async (queryName, fieldname) => {
    console.log(queryName, fieldname);
    let userDoc = await getDocs(
      query(usersCollectionRef, where(fieldname, '==', queryName))
    );

    let tmp = userDoc.docs.map(doc => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    let newUsers = [];

    for (let user of tmp) {
      const subCollection = collection(db, 'users', user.id, 'casinoAccounts');
      const associatedCasinos = await getDocs(subCollection);
      user = {
        ...user,
        casinoAccounts: associatedCasinos.docs.map(d => ({
          id: d.id,
          ...d.data(),
        })),
      };

      newUsers.push(user);
    }

    return newUsers;
  };

  getUserById = async id => {
    const docRef = doc(db, 'users', id);
    return await getDoc(docRef);
  };

  manuallyVerifyUser = async (id, verifiedStatus) => {
    const docRef = doc(db, 'users', id);

    return await updateDoc(docRef, {
      manuallyVerified: verifiedStatus,
    });
  };

  setAccounStatus = async (id, verifiedStatus) => {
    const docRef = doc(db, 'users', id);

    return await updateDoc(docRef, {
      frozenAccount: verifiedStatus,
    });
  };

  addPointsToUser = async (id, finalPointsValue) => {
    const docRef = doc(db, 'users', id);
    console.log(id, finalPointsValue);
    return await updateDoc(docRef, {
      coinCount: finalPointsValue,
    });
  };

  updateFieldForUser = async (id, field, value) => {
    const docRef = doc(db, 'users', id);
    console.log(id, field, value);
    return await updateDoc(docRef, {
      [field]: value,
    });
  };

  deleteUser = async id => {
    // await auth.deleteUser(id);
    return await deleteDoc(doc(db, 'users', id));
  };
}

export default new UsersDataService();

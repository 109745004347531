import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const IDSchema = Yup.object().shape({
  id: Yup.string()
    .length(28, 'ID-ul trebuie sa contina 28 de caractere.')
    .required('Acest camp este obligatoriu.'),
});

export default IDSchema;

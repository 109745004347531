import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  addCasinoItem,
  editCazinoItem,
} from '../../features/casionoItems/rememberCasinoItems';
import { Formik, Form, Field } from 'formik';

import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Select,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
  Divider,
  HStack,
  Text,
} from '@chakra-ui/react';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import CreateRuffleValidationSchema from './CreateRuffleSchema';
import TombolaFormField from './TombolaFormField';
import TombolaArrayForm from './TombolaArrayForm';
import TombolaDataService from '../../services/tombola-services';

function CreeazaTombola() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [prizes, setPrizes] = useState([
    { fromRank: 1, toRank: 100, name: '', image: '' },
  ]);

  const submitForm = async (values, resetForm) => {
    console.log('Submitting the form...');
    console.log(values);
    setIsLoading(true);

    let newValues = {
      ...values,
      isAvailable: true,
      participants: 0,
      ticketsBought: 0,
    };

    // CONVERTING TO INT

    newValues.ticketPrice = parseInt(newValues.ticketPrice);
    for (let i in newValues.prizes) {
      newValues.prizes[i].toRank = parseInt(newValues.prizes[i].toRank);
      newValues.prizes[i].fromRank = parseInt(newValues.prizes[i].fromRank);
    }

    console.log(newValues);

    delete newValues['mainPageImageURL'];

    let specialEventsMap = {
      imageURL: values.mainPageImageURL,
      isEnabled: true,
    };

    try {
      await TombolaDataService.createNewRuffle(newValues);
      await TombolaDataService.removeWinners();
      await TombolaDataService.removeAllTickets();
      await TombolaDataService.setSpecialEvent(specialEventsMap);
      toast.success('Operatiunea s-a realizat cu succes!');
      resetForm({});
      navigate('/tombola');
    } catch (e) {
      console.log(e);
      toast.error(`S-a produs urmatoarea eroare: ${e}`);
    }

    setIsLoading(false);
  };

  const updateForm = (values, setValues) => {
    let newPrizes = [
      ...values.prizes,
      { fromRank: 1, toRank: 100, name: '', image: '' },
    ];

    values.prizes = newPrizes;

    setValues(values);
  };

  const removePrize = (values, setValues, index) => {
    console.log(index);
    values.prizes.splice(index, 1);
    console.log(values);
    setValues(values);
  };

  return (
    <Container mt="3rem" maxW="container.lg">
      <VStack>
        <Link to="/tombola">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Back
          </Button>
        </Link>
        <Spacer />
        <Heading textAlign={'center'} size="xl" py="3" mb="5">
          Creeaza o noua tombola
        </Heading>
      </VStack>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="sm"
        my="2rem"
        p="2rem"
        maxW={'900px'}
      >
        <Box p={8}>
          <Formik
            initialValues={{
              imageURL: '',
              prizes: prizes,
              ticketPrice: 2000,
              endDate: undefined,
              extractionDate: undefined,
              mainPageImageURL: '',
            }}
            onSubmit={(values, { resetForm }) => {
              submitForm(values, resetForm);
            }}
            validationSchema={CreateRuffleValidationSchema}
          >
            {({
              values,
              errors,
              touched,
              dirty,
              isValid,
              setFieldValue,
              setValues,
            }) => {
              return (
                <Form>
                  <TombolaFormField
                    errors={errors}
                    touched={touched}
                    as={Input}
                    name="imageURL"
                    value={values.imageURL}
                    label="URL Imagine Tombola"
                    setFieldValue={setFieldValue}
                  />
                  <TombolaFormField
                    errors={errors}
                    touched={touched}
                    as={Input}
                    name="mainPageImageURL"
                    value={values.mainPageImageURL}
                    label="URL Imagine Pagina Principala"
                    setFieldValue={setFieldValue}
                  />
                  <TombolaFormField
                    errors={errors}
                    touched={touched}
                    as={Input}
                    type="number"
                    name="ticketPrice"
                    value={values.ticketPrice}
                    label="Pret bilet"
                    setFieldValue={setFieldValue}
                  />
                  <TombolaFormField
                    type="datetime-local"
                    errors={errors}
                    touched={touched}
                    as={Input}
                    name="endDate"
                    value={values.endDate}
                    label="Finalul tombolei"
                    setFieldValue={setFieldValue}
                  />
                  <TombolaFormField
                    type="datetime-local"
                    errors={errors}
                    touched={touched}
                    as={Input}
                    name="extractionDate"
                    value={values.extractionDate}
                    label="Data extragerii"
                    setFieldValue={setFieldValue}
                  />

                  {values.prizes.map((e, i) => {
                    return (
                      <>
                        <Box marginLeft="10">
                          <HStack mb="10">
                            {i !== 0 && (
                              <Button
                                onClick={e => removePrize(values, setValues, i)}
                                size="xs"
                                colorScheme="red"
                              >
                                <RiCloseCircleFill />
                              </Button>
                            )}
                            <Heading size="sm">Premiul {i + 1} </Heading>
                          </HStack>

                          {Object.keys(e).map((element, index) => {
                            return (
                              <TombolaArrayForm
                                index={i}
                                type={
                                  element.includes('rank') ? 'number' : 'text'
                                }
                                errors={errors}
                                touched={touched}
                                as={Input}
                                name={element}
                                value={values.prizes[i][element]}
                                label={element}
                                setFieldValue={setFieldValue}
                                values={values}
                              />
                            );
                          })}
                        </Box>
                      </>
                    );
                  })}
                  {Array.isArray(errors.prizes) &&
                    errors.prizes.map((prize, index) => {
                      return (
                        <Box>
                          <Heading mb="10" size="xs" color="red">
                            Premiul {index + 1}
                            {prize &&
                              Object.keys(prize).map(key => {
                                return (
                                  <Text>
                                    - {key}: {prize[key]}
                                  </Text>
                                );
                              })}
                          </Heading>
                        </Box>
                      );
                    })}
                  {typeof errors.prizes === 'string' && (
                    <Heading mb="10" size="xs" color="red">
                      {errors.prizes}
                    </Heading>
                  )}

                  <Button
                    colorScheme="teal"
                    onClick={e => updateForm(values, setValues)}
                  >
                    Adauga premii
                  </Button>

                  <Center mt={'4rem'}>
                    <Button
                      isDisabled={!(dirty && isValid)}
                      isLoading={isLoading}
                      minW={'200px'}
                      loadingText="Se actualizeaza"
                      type="submit"
                      colorScheme="green"
                      width="full"
                      textAlign="center"
                    >
                      Creeaza tombola
                    </Button>
                    {/* <Button
                      minW={'200px'}
                      loadingText="Se actualizeaza"
                      colorScheme="red"
                      textAlign="center"
                      onClick={async () => {
                        await TombolaDataService.addDummyDocs();
                      }}
                    >
                      Creeaza tombola
                    </Button> */}
                  </Center>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <br />
    </Container>
  );
}

export default CreeazaTombola;

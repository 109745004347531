import { configureStore } from '@reduxjs/toolkit';
import addPromoItemReducer from './features/promoItems/addPromoItemSlice';
import checkCurrentUserReducer from './features/checkCurrentUserSlice';
import rememberPromoCarouselItems from './features/promoItems/rememberPromoCarouselItemsSlice';
import rememberCasinoItems from './features/casionoItems/rememberCasinoItems';
import rememberGameItems from './features/gamesItems/rememberGameItemsSlice';
import rememberGameItemsV2 from './features/gamesItemsV2/rememberGameItemsSliceV2';
import addOrEditGameItemSlice from './features/gamesItems/addOrEditGameItemSlice';
import addOrEditGameItemSliceV2 from './features/gamesItemsV2/addOrEditGameItemSliceV2';
import addOrEditCasinoItems from './features/casionoItems/addOrEditCasinoItemsSlice';
import addOrEditInAppGamesItemsSlice from './features/inAppGamesItems/addOrEditInAppGamesItems.js';
import rememberStoreItemsSlice from './features/storeItems/rememberStoreItems';
import addOrEditStoreItems from './features/storeItems/addOrEditStoreItems';
import rememberOrderItemsSlice from './features/orderItems/rememberOrderItemsSlice';
import rememberMissionItemsSlice from './features/missionItems/rememberMissionItemsSlice';
import addOrEditMissionItemsSlice from './features/missionItems/addOrEditMissionItemsSlice';


export const store = configureStore({
  reducer: {
    rememberPromoCarouselItems: rememberPromoCarouselItems,
    addOrEditCasinoItems: addOrEditCasinoItems,
    rememberCasinoItems: rememberCasinoItems,
    addPromoItem: addPromoItemReducer,
    checkCurrentUser: checkCurrentUserReducer,
    // rememberGameItems: rememberGameItems,
    // addOrEditGameItem: addOrEditGameItemSlice,
    addOrEditInAppGamesItems: addOrEditInAppGamesItemsSlice,
    rememberStoreItems: rememberStoreItemsSlice,
    addOrEditStoreItems: addOrEditStoreItems,
    rememberOrderItems: rememberOrderItemsSlice,
    rememberGameItemsV2: rememberGameItemsV2,
    addOrEditGameItemV2: addOrEditGameItemSliceV2,
    rememberMissionItems: rememberMissionItemsSlice,
    addOrEditMissionItems: addOrEditMissionItemsSlice
  },
});

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  add: true,
  category: {
    value: '',
    showUiError: false,
  },
  id: {
    value: '',
    showUiError: false,
  },
  name: {
    value: '',
    showUiError: false,
  },
  imageURL: {
    value: '',
    showUiError: false,
  },
  iframeURL: {
    value: '',
    showUiError: false,
  },
  webURL: {
    value: '',
    showUiError: false,
  },
};
const addOrEditGameItemSlice = createSlice({
  name: 'addOrEditGameItem',
  initialState,
  reducers: {
    clearState: state => {
      state.add = true;
      state.category = {
        value: '',
        showUiError: false,
      };
      state.id = {
        value: '',
        showUiError: false,
      };
      state.name = {
        value: '',
        showUiError: false,
      };
      state.imageURL = {
        value: '',
        showUiError: false,
      };
      state.iframeURL = {
        value: '',
        showUiError: false,
      };
      state.webURL = {
        value: '',
        showUiError: false,
      };
    },
    prepareStateForEdit: (state, { payload }) => {
      state.add = false;
      state.category = {
        value: payload.category,
        showUiError: false,
      };
      state.id = {
        value: payload.id,
        showUiError: false,
      };
      state.name = {
        value: payload.name,
        showUiError: false,
      };
      state.imageURL = {
        value: payload.imageURL,
        showUiError: false,
      };
      state.iframeURL = {
        value: payload.iframeURL,
        showUiError: false,
      };
      state.webURL = {
        value: payload.webURL,
        showUiError: false,
      };
    },
    updateState: (state, { payload }) => {
      state[payload.name].value = payload.value;
    },
  },
});

export default addOrEditGameItemSlice.reducer;
export const { clearState, updateState, prepareStateForEdit } =
  addOrEditGameItemSlice.actions;

import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyAzgTfvAHI3glE-CmG98qcHd4Px2b5qYgc',
  authDomain: 'cazino365-admin.firebaseapp.com',
  projectId: 'cazino365-admin',
  storageBucket: 'cazino365-admin.appspot.com',
  messagingSenderId: '513754960022',
  appId: '1:513754960022:web:bedce8f8f17c3968c672b7',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth;
export const signIn = signInWithEmailAndPassword;

import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Select,
  Switch,
  Textarea,
  Grid,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import AddOrEditFortuneWheelSchema from './AddOrEditFortuneWheelSchema';

import InAppGamesDataService from '../../services/in-app-games-services';
import {
  clearState,
  updateState,
  updateValuesArray,
} from '../../features/inAppGamesItems/addOrEditInAppGamesItems';

function AddOrEditFortuneWheel(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /// STATE

  const [isLoading, setIsLoading] = useState(false);

  /// STORE
  const add = useSelector(state => state.addOrEditInAppGamesItems.add);
  const id = useSelector(state => state.addOrEditInAppGamesItems.id);
  const maxPoints = useSelector(
    state => state.addOrEditInAppGamesItems.maxPoints
  );
  const wheelConfiguration = useSelector(
    state => state.addOrEditInAppGamesItems.newWheelConfiguration
  );
  const isJackpotWon = useSelector(
    state => state.addOrEditInAppGamesItems.isJackpotWon
  );
  const jackpotValue = useSelector(
    state => state.addOrEditInAppGamesItems.jackpotValue
  );
  const wheelNumbers = useSelector(
    state => state.addOrEditInAppGamesItems.newValues
  );
  const wheelNumbersProbabilities = useSelector(
    state => state.addOrEditInAppGamesItems.newValuesProbabilities
  );
  /// LOGIC

  const submitForm = async (values, { setFieldError }) => {
    setIsLoading(true);

    let newWheelConfiguration = [];
    for (let val of values.wheelConfiguration) {
      newWheelConfiguration.push(parseInt(val));
    }

    let newWheelValues = [];
    for (let val of values.wheelNumbers) {
      newWheelValues.push(parseInt(val));
    }

    let newWheelValuesProbabilities = [];

    for (let val of values.wheelNumbersProbabilities) {
      newWheelValuesProbabilities.push(parseFloat(val));
    }

    const newFortuneWheelStats = {
      maxPoints: parseInt(values.maxPoints),
      jackpotValue: parseInt(values.jackpotValue),
      isJackpotWon: values.isJackpotWon,
      newWheelConfiguration: newWheelConfiguration,
      newValues: newWheelValues,
      newValuesProbabilities: newWheelValuesProbabilities,
    };

    console.log(newFortuneWheelStats);

    try {
      await InAppGamesDataService.editFortuneWheelValues(newFortuneWheelStats);
      dispatch(clearState());
      toast.success('Operatiunea s-a realizat cu succes!');
      setIsLoading(false);
      navigate('/roata-norocului');
      // TODO: Implement the caching mechanism if there are too many reads
    } catch (e) {
      console.log(e);
      toast.error('S-a produs o eraore. Incearca din nou.');
      setIsLoading(false);
    }
  };

  return (
    <Container mt="3rem" maxW="container.lg">
      <br />
      <VStack>
        <Link to="/roata-norocului">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
            onClick={() => {
              dispatch(clearState());
            }}
          >
            Back
          </Button>
        </Link>
        <Spacer />
        <Heading textAlign={'center'} size="xl" py="3" mb="5">
          {add ? 'Adauga un item la cazinouri' : 'Editeaza configutația roții'}
        </Heading>
        <Heading></Heading>
      </VStack>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="sm"
        my="2rem"
        p="2rem"
        maxW={'900px'}
      >
        <Box p={8}>
          <Formik
            initialValues={{
              id: id.value,
              maxPoints: maxPoints.value,
              isJackpotWon: isJackpotWon.value,
              jackpotValue: jackpotValue.value,
              wheelNumbers: wheelNumbers.value,
              wheelConfiguration: wheelConfiguration.value,
              wheelNumbersProbabilities: wheelNumbersProbabilities.value,
            }}
            onSubmit={(values, errors) => {
              submitForm(values, errors);
            }}
            validationSchema={AddOrEditFortuneWheelSchema}
          >
            {({ values, errors, touched, dirty, isValid, setFieldValue }) => {
              return (
                <Form>
                  <FormControl
                    isInvalid={errors.maxPoints && touched.maxPoints}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="maxPoints"
                      value={values.maxPoints}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('maxPoints', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Numar maxim de puncte alocat la roata</FormLabel>
                    {errors.maxPoints && touched.maxPoints ? (
                      <FormErrorMessage>{errors.maxPoints}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.jackpotValue && touched.jackpotValue}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="jackpotValue"
                      value={values.jackpotValue}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('jackpotValue', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Valoare Jackpot</FormLabel>
                    {errors.jackpotValue && touched.jackpotValue ? (
                      <FormErrorMessage>{errors.jackpotValue}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl mb={8} display="flex" alignItems="center">
                    <FormLabel htmlFor="jackpot-won" mb="0">
                      Blocheaza jackpot-ul{' '}
                      {values.isJackpotWon ? ' (DA)' : ' (NU)'}
                    </FormLabel>
                    <Field
                      type="checkbox"
                      name="isJackpotWon"
                      as={Switch}
                      id="jackpot-won"
                      isChecked={values.isJackpotWon ? true : false}
                    />

                    <Spacer />
                  </FormControl>

                  <Heading size={'md'}> Valorile Roții</Heading>
                  {/* <Text fontWeight={'bold'} color={'red'}>
                    {' '}
                    Atentie! Toate numerele de pe slice-uri trebuie sa se
                    regaseasca in valorile roții din campul de mai sus
                  </Text> */}
                  <br />
                  <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                    {wheelNumbers.value.map((wheelValue, i) => {
                      return (
                        <FormControl
                          isInvalid={
                            errors.wheelNumbers
                              ? errors.wheelNumbers[i] && errors.wheelNumbers[i]
                              : false
                          }
                          mb={8}
                          variant="floating"
                          isRequired
                        >
                          <Field
                            as={Input}
                            name={'wheelNumbers[' + i + ']'}
                            value={values.wheelNumbers[i]}
                            onChange={e => {
                              dispatch(
                                updateValuesArray({
                                  arrayName: 'newValues',
                                  value: e.target.value,
                                  index: i,
                                })
                              );
                              let arrNew = [...values.wheelNumbers];
                              arrNew[i] = e.target.value;
                              setFieldValue(
                                'wheelNumbers[' + i + ']',
                                arrNew[i]
                              );
                              console.log(errors.wheelNumbers);
                            }}
                            placeholder=" "
                          />

                          <FormLabel>Valoarea {i + 1}</FormLabel>
                          {errors.wheelNumbers && touched.wheelNumbers ? (
                            <FormErrorMessage>
                              {errors.wheelNumbers}
                            </FormErrorMessage>
                          ) : null}
                        </FormControl>
                      );
                    })}
                  </Grid>

                  <Heading size={'md'}> Probabilitatile Roții</Heading>
                  {/* <Text fontWeight={'bold'} color={'red'}>
                    {' '}
                    Atentie! Toate numerele de pe slice-uri trebuie sa se
                    regaseasca in valorile roții din campul de mai sus
                  </Text> */}
                  <br />
                  <FormControl
                    isInvalid={
                      errors.wheelNumbersProbabilities
                        ? errors.wheelNumbersProbabilities &&
                          errors.wheelNumbersProbabilities
                        : false
                    }
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                      {wheelNumbersProbabilities.value.map((wheelValue, i) => {
                        console.log(i);
                        return (
                          <FormControl>
                            <Field
                              as={Input}
                              name={'wheelNumbersProbabilities[' + i + ']'}
                              value={values.wheelNumbersProbabilities[i]}
                              onChange={e => {
                                dispatch(
                                  updateValuesArray({
                                    arrayName: 'newValuesProbabilities',
                                    value: e.target.value,
                                    index: i,
                                  })
                                );
                                let arrNew = [
                                  ...values.wheelNumbersProbabilities,
                                ];
                                arrNew[i] = e.target.value;
                                setFieldValue(
                                  'wheelNumbersProbabilities[' + i + ']',
                                  arrNew[i]
                                );
                                console.log(errors.wheelNumbersProbabilities);
                              }}
                              placeholder=" "
                            />

                            <FormLabel>
                              Probabilitate {values.wheelNumbers[i]}
                            </FormLabel>
                          </FormControl>
                        );
                      })}
                    </Grid>
                    {errors.wheelNumbersProbabilities ||
                    (errors.wheelNumbersProbabilities &&
                      touched.wheelNumbersProbabilities) ? (
                      <FormErrorMessage>
                        {errors.wheelNumbersProbabilities}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <Heading size={'md'}> Configuratia Roții</Heading>
                  <Text fontWeight={'bold'} color={'red'}>
                    {' '}
                    Atentie! Toate numerele de pe slice-uri trebuie sa se
                    regaseasca in valorile roții din campul de mai sus
                  </Text>
                  <br />

                  <FormControl
                    isInvalid={
                      errors.wheelConfiguration
                        ? errors.wheelConfiguration && errors.wheelConfiguration
                        : false
                    }
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Grid templateColumns="repeat(3, 1fr)" gap={6}>
                      {wheelConfiguration.value.map((wheelValue, i) => {
                        return (
                          <>
                            <Field
                              as={Input}
                              name={'wheelConfiguration[' + i + ']'}
                              value={values.wheelConfiguration[i]}
                              onChange={e => {
                                dispatch(
                                  updateValuesArray({
                                    arrayName: 'newWheelConfiguration',
                                    value: e.target.value,
                                    index: i,
                                  })
                                );
                                let arrNew = [...values.wheelConfiguration];
                                arrNew[i] = e.target.value;
                                setFieldValue(
                                  'wheelConfiguration[' + i + ']',
                                  arrNew[i]
                                );
                                console.log(errors.wheelConfiguration);
                              }}
                              placeholder=" "
                            />
                            <FormLabel>Slice {i + 1}</FormLabel>
                          </>
                        );
                      })}
                    </Grid>
                    {errors.wheelConfiguration ||
                    (errors.wheelConfiguration &&
                      touched.wheelConfiguration) ? (
                      <FormErrorMessage>
                        {errors.wheelConfiguration}
                      </FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <Center mt={'4rem'}>
                    <Button
                      isDisabled={!(dirty && isValid)}
                      isLoading={isLoading}
                      minW={'200px'}
                      loadingText="Se actualizeaza"
                      type="submit"
                      colorScheme="green"
                      width="full"
                      textAlign="center"
                    >
                      {add ? 'Adauga' : 'Editeaza'}
                    </Button>
                  </Center>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <br />
    </Container>
  );
}

export default AddOrEditFortuneWheel;

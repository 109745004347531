import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Acest camp trebuie sa fie o adresa valida de email.')
    .required('Acest camp este obligatoriu.'),
});

export default EmailSchema;

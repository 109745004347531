import React, { useEffect, useState } from 'react';
import {
  Heading,
  Container,
  Stack,
  Button,
  Box,
  Text,
  Image,
  Center,
  HStack,
  Spacer,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiArrowNarrowLeft, HiCheckCircle } from 'react-icons/hi';
import { FaPlus } from 'react-icons/fa';
import MissionsDataService from '../../services/mission-services';

import { useSelector, useDispatch } from 'react-redux';
import {
  setActiveMissionItems,
  setInactiveMissionsItems,
} from '../../features/missionItems/rememberMissionItemsSlice';
import {
  clearState,
  prepareStateForEdit,
} from '../../features/missionItems/addOrEditMissionItemsSlice';

function Missions() {
  const dispatch = useDispatch();

  const activeMission = useSelector(
    state => state.rememberMissionItems.activeMission
  );
  const inactiveMissions = useSelector(
    state => state.rememberMissionItems.inactiveMissions
  );

  const getMissionsInfo = async () => {
    console.log(inactiveMissions);
    console.log(activeMission);
    const missions = await MissionsDataService.getAllMissions();

    let auxInactiveMissions = [];

    for (let mission of missions) {
      if (mission.isActive) {
        let newActiveMission = { ...mission, toEditIndex: -2 };
        console.log('newActiveMission', newActiveMission);
        dispatch(setActiveMissionItems({ activeMission: newActiveMission }));
      } else {
        auxInactiveMissions.push(mission);
      }
    }
    dispatch(
      setInactiveMissionsItems({ inactiveMissions: auxInactiveMissions })
    );
  };

  useEffect(() => {
    if (inactiveMissions.length === 0) {
      console.log('in USE EFFECT');
      getMissionsInfo();
    } else {
      console.log('Restoring from store...');
    }
  }, []);

  return (
    <Container mt="3rem" minWidth="95%">
      <Heading size="xl">Misiuni</Heading>

      <HStack mt="3">
        <Link to="/admin-dashboard">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
          >
            Dashboard
          </Button>
        </Link>
        <Spacer />
        <Link to="/edit-mission">
          <Button
            onClick={() => {
              dispatch(clearState);
            }}
            colorScheme={'purple'}
          >
            {' '}
            <FaPlus /> <Box w={2} />
            Adauga o nouă misiune
          </Button>
        </Link>
      </HStack>
      <Heading mt={10} size="lg">
        Misiunea activa
      </Heading>
      {activeMission.name ? (
        <Link to="/edit-mission">
          <Button
            m={5}
            p={5}
            borderWidth="1px"
            shadow="md"
            width="full"
            minHeight={'65px'}
            onClick={() =>
              dispatch(prepareStateForEdit({ ...activeMission, index: -2 }))
            }
          >
            <Center>
              <Heading size="md">{activeMission.name}</Heading>
            </Center>
          </Button>
        </Link>
      ) : (
        <Heading mt={3} size="md">
          <i>Nu exista nicio misiune activa</i>
        </Heading>
      )}
      <Heading mt={10} size="lg">
        Misiuni inactive
      </Heading>
      {inactiveMissions &&
        inactiveMissions.map((inactiveMission, index) => {
          return (
            <Link to="/edit-mission">
              <Button
                m={5}
                p={5}
                key={`mission-${index}`}
                borderWidth="1px"
                shadow="md"
                width="full"
                minHeight={'65px'}
                onClick={() =>
                  dispatch(
                    prepareStateForEdit({ ...inactiveMission, index: index })
                  )
                }
              >
                <Center>
                  <Heading size="md">{inactiveMission.name}</Heading>
                </Center>
              </Button>
            </Link>
          );
        })}
    </Container>
  );
}

export default Missions;

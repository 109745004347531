import './App.css';
import LogIn from './pages/LogIn/LogIn';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './pages/ProtectedRoute/ProtectedRoute';
import Dashboards from './pages/Dashboard/Dashboards';
import Layout from './Layout/Layout';
import AddOrEdit from './pages/AddOrEdit/AddOrEditFormik';
import PromoItems from './pages/PromoItems/PromoItems';
import CasinoItems from './pages/CasinoItems/CasinoItems';
import GamesItems from './pages/GamesItems/GamesItems';
import AddOrEditCazino from './pages/AddOrEditCazino/AddOrEditCazino';
import AddOrEditGame from './pages/AddOrEditGame/AddOrEditGame';
import RoataNorocului from './pages/RoataNorocului/RoataNorocului';
import { Heading } from '@chakra-ui/react';
import GamesAccordion from './pages/GamesItems/GamesAccordion/GamesAccordion';
import AddOrEditFortuneWheel from './pages/AddOrEditFortuneWheel/AddOrEditFortuneWheel';
import Magazin from './pages/Magazin/Magazin';
import AddOrEditShopItem from './pages/AddorEditShopItem/AddOrEditShopItem';
import StoreDashboard from './pages/Magazin/StoreDashboard';
import Comenzi from './pages/Magazin/Comenzi';
import Utilizatori from './pages/Utilizatori/Utilizatori';
import JibberishWork from './components/JibberishWork/JibberishWork';
import AddOrEditGameMeta from './pages/AddOrEditGameMeta/AddOrEditGameMeta';
import Tombola from './pages/Tombola/Tombola';
import CreeazaTombola from './pages/Tombola/CreeazaTombola';
import Missions from './pages/Missions/Missions';
import AddOrEditMission from './pages/Missions/AddOrEditMission';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LogIn />} />
          <Route
            path="admin-dashboard"
            element={
              <ProtectedRoute>
                <Dashboards />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="promo-items"
            element={
              <ProtectedRoute>
                <PromoItems />
              </ProtectedRoute>
            }
          ></Route>

          <Route
            path="add-promo-item"
            element={
              <ProtectedRoute>
                <AddOrEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-promo-item"
            element={
              <ProtectedRoute>
                <AddOrEdit />
              </ProtectedRoute>
            }
          />
          <Route
            path="casino-items"
            element={
              <ProtectedRoute>
                <CasinoItems />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="add-casino-item"
            element={
              <ProtectedRoute>
                <AddOrEditCazino />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-casino-item"
            element={
              <ProtectedRoute>
                <AddOrEditCazino />
              </ProtectedRoute>
            }
          />
          <Route
            path="games-items"
            element={
              <ProtectedRoute>
                <GamesItems />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="games-items/:category"
            element={
              <ProtectedRoute>
                <GamesAccordion />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="add-game-item"
            element={
              <ProtectedRoute>
                <AddOrEditGame />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-game-item"
            element={
              <ProtectedRoute>
                <AddOrEditGame />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-game-category-meta"
            element={
              <ProtectedRoute>
                <AddOrEditGameMeta />
              </ProtectedRoute>
            }
          />
          <Route
            path="roata-norocului"
            element={
              <ProtectedRoute>
                <RoataNorocului />
              </ProtectedRoute>
            }
          />
          <Route
            path="edit-roata-norocului"
            element={
              <ProtectedRoute>
                <AddOrEditFortuneWheel />
              </ProtectedRoute>
            }
          />
          <Route
            path="magazin-dashboard"
            element={
              <ProtectedRoute>
                <StoreDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="magazin"
            element={
              <ProtectedRoute>
                <Magazin />
              </ProtectedRoute>
            }
          />
          <Route
            path="comenzi"
            element={
              <ProtectedRoute>
                <Comenzi />
              </ProtectedRoute>
            }
          />
          <Route
            path="utilizatori"
            element={
              <ProtectedRoute>
                <Utilizatori />
              </ProtectedRoute>
            }
          />
          <Route
            path="utilizatori/:id"
            element={
              <ProtectedRoute>
                <Utilizatori />
              </ProtectedRoute>
            }
          />
          <Route
            path="editeaza-item-magazin"
            element={
              <ProtectedRoute>
                <AddOrEditShopItem />
              </ProtectedRoute>
            }
          />
          <Route
            path="adauga-item-magazin"
            element={
              <ProtectedRoute>
                <AddOrEditShopItem />
              </ProtectedRoute>
            }
          />
          <Route
            path="tombola"
            element={
              <ProtectedRoute>
                <Tombola />
              </ProtectedRoute>
            }
          />

          <Route
            path="create-new-ruffle"
            element={
              <ProtectedRoute>
                <CreeazaTombola />
              </ProtectedRoute>
            }
          />
          <Route
            path="missions"
            element={
              <ProtectedRoute>
                <Missions />
              </ProtectedRoute>
            }
          />

          <Route
            path="edit-mission"
            element={
              <ProtectedRoute>
                <AddOrEditMission />
              </ProtectedRoute>
            }
          />

          <Route
            path="jibberish-work"
            element={
              <ProtectedRoute>
                <JibberishWork />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const AddOrEditSchema = Yup.object().shape({
  isJackpotWon: Yup.bool().required('isJackpotWon este de tip boolean'),
  maxPoints: Yup.number()
    .typeError('Ratingul trebuie sa fie un numar')
    .min(0, 'Ratingul trebuie sa fie mai mare decat 0')
    .required('Ratingul este necesar'),
  jackpotValue: Yup.number()
    .typeError('Valoarea jackpot-ului trebuie sa fie un numar')
    .min(0, 'Jackpot-ul trebuie sa fie mai mare decat 0')
    .required('Valoarea jackpotului este un camp necesar'),
  wheelConfiguration: Yup.array()
    .of(
      Yup.number()
        .typeError('Valorile punctelor trebuie să fie numere naturale.')
        .min(0, 'Minimul de puncte trebuie sa fie 0')
        .required("Fiecare câmp din 'Valorile Roții' trebuie completat")
    )
    .test({
      message:
        'Configuratia trebuie sa cuprinda doar numere declarate mai sus.',
      test: (configuration, context) => {
        console.log(configuration, context.parent);
        for (let configurationValue of configuration) {
          if (!context.parent.wheelNumbers.includes(configurationValue)) {
            console.log(configurationValue);
            return false;
          }
        }
        return true;
      },
    }),
  wheelNumbers: Yup.array().of(
    Yup.number()
      .typeError('Valorile punctelor trebuie să fie numere naturale.')
      .min(0, 'Minimul de puncte trebuie sa fie 0')
      .required("Fiecare câmp din 'Valorile Roții' trebuie completat")
  ),
  wheelNumbersProbabilities: Yup.array()
    .of(
      Yup.number()
        .typeError('Valorile punctelor trebuie să fie numere naturale.')
        .min(0, 'Minimul de puncte trebuie sa fie 0')
        .required("Fiecare câmp din 'Valorile Roții' trebuie completat")
    )
    .test({
      message: 'Probabilitatile trebuie sa insumeze 100.',
      test: wheelNumbersProbabilities => {
        const sum =
          wheelNumbersProbabilities?.reduce((acc, contact) => {
            return acc + contact;
          }, 0) || 0;
        return sum === 100;
      },
    }),
});

export default AddOrEditSchema;

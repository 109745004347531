import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  add: true,
  index: -1,
  id: {
    value: '',
    showUiError: false,
  },
  productId: {
    value: '',
    showUiError: false,
  },
  price: {
    value: '',
    showUiError: false,
  },
  description: {
    value: '',
    showUiError: false,
  },
  deliveryDate: {
    value: '',
    showUiError: false,
  },
  name: {
    value: '',
    showUiError: false,
  },
  image: {
    value: '',
    showUiError: false,
  },
  stock: {
    value: '',
    showUiError: false,
  },
  type: {
    value: '',
    showUiError: false,
  },
  redirectURL: {
    value: '',
    showUiError: false,
  },
};

const addOrEditStoreItems = createSlice({
  name: 'addOrEditStoreItems',
  initialState,
  reducers: {
    clearState: state => {
      state.add = true;
      state.index = -1;
      state.id = {
        value: '',
        showUiError: false,
      };
      state.productId = {
        value: '',
        showUiError: false,
      };
      state.price = {
        value: '',
        showUiError: false,
      };
      state.description = {
        value: '',
        showUiError: false,
      };
      state.deliveryDate = {
        value: '',
        showUiError: false,
      };
      state.name = {
        value: '',
        showUiError: false,
      };
      state.image = {
        value: '',
        showUiError: false,
      };
      state.stock = {
        value: '',
        showUiError: false,
      };
      state.type = {
        value: '',
        showUiError: false,
      };
      state.redirectURL = {
        value: '',
        showUiError: false,
      };
    },
    prepareStateForEdit: (state, { payload }) => {
      state.add = false;
      state.index = payload.index;
      state.id = {
        value: payload.id,
        showUiError: false,
      };
      state.productId = {
        value: payload.productId,
        showUiError: false,
      };
      state.price = {
        value: payload.price,
        showUiError: false,
      };
      state.description = {
        value: payload.description,
        showUiError: false,
      };

      state.deliveryDate = {
        value: payload.deliveryDate,
        showUiError: false,
      };
      state.name = {
        value: payload.name,
        showUiError: false,
      };
      state.image = {
        value: payload.image,
        showUiError: false,
      };
      state.stock = {
        value: payload.stock,
        showUiError: false,
      };
      state.type = {
        value: payload.type,
        showUiError: false,
      };
      state.redirectURL = {
        value: payload.redirectURL,
        showUiError: false,
      };
    },
    updateState: (state, { payload }) => {
      state[payload.name].value = payload.value;
    },
  },
});

export const { updateState, clearState, prepareStateForEdit } =
  addOrEditStoreItems.actions;

export default addOrEditStoreItems.reducer;

import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';

import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link, useNavigate } from 'react-router-dom';
import AddOrEditGameMetaSchema from './AddOrEditGameMetaValidationSchema';
import {
  FormControl,
  FormLabel,
  Input,
  Heading,
  Container,
  Box,
  Select,
  Switch,
  Textarea,
  Spacer,
  Button,
  Center,
  VStack,
  FormErrorMessage,
} from '@chakra-ui/react';

import {
  clearState,
  updateState,
} from '../../features/gamesItemsV2/addOrEditGameItemSliceV2';
import { editGameCategory } from '../../features/gamesItemsV2/rememberGameItemsSliceV2';
import GamesDataService from '../../services/games-servicesV2';

function AddOrEditGameMeta() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const category = useSelector(state => state.addOrEditGameItemV2.category);
  const title = useSelector(state => state.addOrEditGameItemV2.title);
  const priority = useSelector(state => state.addOrEditGameItemV2.priority);
  const isVisible = useSelector(state => state.addOrEditGameItemV2.isVisible);
  const icon = useSelector(state => state.addOrEditGameItemV2.icon);

  const gameCollection = useSelector(state => state.rememberGameItemsV2);

  const icons_array = [
    'BET',
    'GRAPE',
    'WINNER',
    'CASINO',
    'TOKEN',
    'DIAMOND',
    'MEDAL',
    '777',
    'MONARCHY',
    'JACKPOT',
  ];

  const submitForm = async values => {
    setIsLoading(true);

    const newCategoryMeta = {
      icon: values.icon.trim(),
      title: values.title.trim(),
      priority: parseInt(values.priority),
      isVisible: values.isVisible,
    };
    try {
      await GamesDataService.editGameCategory(values.category, newCategoryMeta);
      dispatch(
        editGameCategory({
          ...newCategoryMeta,
          category: values.category,
        })
      );

      toast.success('Operatiunea s-a realizat cu succes!');
      setIsLoading(false);
      navigate(`/games-items/${values.category}`);
    } catch (e) {
      console.log(e);
      toast.error('S-a produs o eraore. Incearca din nou.');
      setIsLoading(false);
    }
  };

  return (
    <Container mt="3rem" maxW="container.lg">
      <VStack>
        <Link to="/games-items">
          <Button
            leftIcon={<HiArrowNarrowLeft />}
            colorScheme="green"
            variant="outline"
            onClick={() => {
              dispatch(clearState());
            }}
          >
            Back
          </Button>
        </Link>
        <Spacer />
        <Heading textAlign={'center'} size="xl" py="3" mb="5">
          Editeaza categoria de jocuri
        </Heading>
      </VStack>

      <Box
        borderWidth="1px"
        borderRadius="lg"
        boxShadow="sm"
        my="2rem"
        p="2rem"
        maxW={'900px'}
      >
        <Box p={8}>
          <Formik
            initialValues={{
              category: category.value,
              title: title.value,
              icon: icon.value,
              priority: priority.value,
              isVisible: isVisible.value,
            }}
            onSubmit={values => {
              submitForm(values);
            }}
            validationSchema={AddOrEditGameMetaSchema}
          >
            {({ values, errors, touched, dirty, isValid, setFieldValue }) => {
              return (
                <Form>
                  <FormControl
                    isInvalid={errors.category && touched.category}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Select}
                      name="category"
                      isDisabled={true}
                      value={values.category}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('category', e.target.value);
                      }}
                      placeholder=" "
                    >
                      {Object.keys(gameCollection).map((e, index) => {
                        return <option value={e}>{e}</option>;
                      })}
                    </Field>
                    <FormLabel>Categorie joc</FormLabel>
                    {errors.category && touched.category ? (
                      <FormErrorMessage>{errors.category}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.title && touched.title}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Input}
                      name="title"
                      value={values.title}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('title', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Titlu categorie</FormLabel>
                    {errors.title && touched.title ? (
                      <FormErrorMessage>{errors.title}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isRequired
                    isInvalid={errors.priority && touched.priority}
                    mb={8}
                    variant="floating"
                  >
                    <Field
                      name="priority"
                      as={Input}
                      value={values.priority}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('priority', e.target.value);
                      }}
                      placeholder=" "
                    />

                    <FormLabel>Prioritate</FormLabel>
                    {errors.priority && touched.priority ? (
                      <FormErrorMessage>{errors.priority}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl
                    isInvalid={errors.icon && touched.icon}
                    mb={8}
                    variant="floating"
                    isRequired
                  >
                    <Field
                      as={Select}
                      name="icon"
                      value={values.icon}
                      onChange={e => {
                        dispatch(
                          updateState({
                            value: e.target.value,
                            name: e.target.name,
                          })
                        );
                        setFieldValue('icon', e.target.value);
                      }}
                      placeholder=" "
                    >
                      {icons_array.map((e, index) => {
                        return <option value={e}>{e}</option>;
                      })}
                    </Field>

                    <FormLabel>Iconiță categorie</FormLabel>
                    {errors.icon && touched.icon ? (
                      <FormErrorMessage>{errors.icon}</FormErrorMessage>
                    ) : null}
                  </FormControl>

                  <FormControl mb={8} display="flex" alignItems="center">
                    <FormLabel htmlFor="visible-category" mb="0">
                      Este public? {values.isVisible ? ' (DA)' : ' (NU)'}
                    </FormLabel>
                    <Field
                      type="checkbox"
                      name="isVisible"
                      as={Switch}
                      id="visible-category"
                    />
                    <Spacer />
                  </FormControl>

                  <Center mt={'4rem'}>
                    <Button
                      isDisabled={!(dirty && isValid)}
                      isLoading={isLoading}
                      minW={'200px'}
                      loadingText="Se actualizeaza"
                      type="submit"
                      colorScheme="green"
                      width="full"
                      textAlign="center"
                    >
                      Editeaza
                    </Button>
                  </Center>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <br />
    </Container>
  );
}

export default AddOrEditGameMeta;

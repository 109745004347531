import { db } from './firebase-users-config';

import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  Timestamp,
  increment,
} from 'firebase/firestore';

class TransactionsDataService {
  getTransactionById = async id => {
    const docRef = doc(db, 'transactions', id);
    console.log(id);
    return await getDoc(docRef);
  };

  addTransaction = (newTransaction, id) => {
    newTransaction = {
      ...newTransaction,
      timestamp: Timestamp.fromDate(new Date()),
    };
    console.log(newTransaction, id);

    const docRef = doc(db, 'transactions', id);

    return updateDoc(docRef, {
      transactions: arrayUnion(newTransaction),
      count: increment(1)
    });
  };

  deleteTransaction = id => {
    console.log(id);
    const docRef = doc(db, 'transactions', id);
    return updateDoc(docRef, {
      transactions: [],
    });
  };
}

export default new TransactionsDataService();

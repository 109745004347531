import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  egyptQuest: {
    id: 'egyptQuest',
    name: 'Egypt Quest',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  buySpecials: {
    id: 'buySpecials',
    name: 'Cumpără Speciala',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  fruits: {
    id: 'fruits',
    name: 'Cu fructe',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  jackpot: {
    id: 'jackpot',
    name: 'Jackpot',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  likeSlots: {
    id: 'likeSlots',
    name: 'Ca la Aparate',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  megaways: {
    id: 'megaways',
    name: 'Megaways',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  mostPlayed: {
    id: 'mostPlayed',
    name: 'Cele mai Jucate',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  newest: {
    id: 'newest',
    name: 'Cele mai Noi',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  ourRecommendations: {
    id: 'ourRecommendations',
    name: 'Recomandările noastre',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  sevens: {
    id: 'sevens',
    name: 'Cu Șeptari',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  optional1: {
    id: 'optional1',
    name: 'Cu Șeptari',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  optional2: {
    id: 'optional2',
    name: 'Cu Șeptari',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  optional3: {
    id: 'optional3',
    name: 'Cu Șeptari',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  optional4: {
    id: 'optional4',
    name: 'Cu Șeptari',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
  optional5: {
    id: 'optional5',
    name: 'Cu Șeptari',
    title: '',
    priority: 0,
    isVisible: true,
    icon: 'JACKPOT',
    games: [],
  },
};

const rememberGameItemSlice = createSlice({
  name: 'GameItems',
  initialState,
  reducers: {
    setGameItems: (state, { payload }) => {
      console.log('Payload', payload);
      state[payload.id].games = payload.games;
      state[payload.id].title = payload.title;
      state[payload.id].name = payload.title;
      state[payload.id].priority = payload.priority;
      state[payload.id].isVisible = payload.isVisible;
      state[payload.id].icon = payload.icon;
    },
    addGameItem: (state, { payload }) => {
      state[payload.category].games = {
        ...state[payload.category].games,
        [payload.id]: payload.newGame,
      };
    },
    removeGameItem: (state, { payload }) => {
      delete state[payload.category].games[payload.id];
    },
    editGameItem: (state, { payload }) => {
      state[payload.category].games[payload.id] = payload.newGame;
    },
    editGameCategory: (state, { payload }) => {
      state[payload.category].title = payload.title;
      state[payload.category].name = payload.title;
      state[payload.category].priority = payload.priority;
      state[payload.category].isVisible = payload.isVisible;
      state[payload.category].icon = payload.icon;
    },
  },
});

export default rememberGameItemSlice.reducer;
export const {
  setGameItems,
  addGameItem,
  removeGameItem,
  editGameItem,
  editGameCategory,
} = rememberGameItemSlice.actions;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeMission: {},
  inactiveMissions: [],
};

const rememberMissionItemsSlice = createSlice({
  name: 'MissionItems',
  initialState,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducers: {
    setInactiveMissionsItems: (state, { payload }) => {
      console.log(payload.inactiveMissions);
      state.inactiveMissions = payload.inactiveMissions;
      console.log(state.inactiveMissions);
    },
    setActiveMissionItems: (state, { payload }) => {
      console.log(payload.activeMission);
      state.activeMission = payload.activeMission;
    },
    addInactiveMissionItem: (state, { payload }) => {
      state.inactiveMissions.push(payload.inactiveMission);
    },
    editMission: (state, { payload }) => {
      console.log(payload);
      if (payload.isActive === false) {
        let newInactiveMissions = [...state.inactiveMissions];
        // new entry
        if (payload.toEditIndex === -1) {
          newInactiveMissions.push(payload);
        }
        // from active to inactive
        else if (payload.toEditIndex === -2) {
          state.activeMission = {};
          newInactiveMissions.push(payload);
        }
        // from inactive to inactive
        else {
          newInactiveMissions[payload.toEditIndex] = payload;
        }
        state.inactiveMissions = newInactiveMissions;
      }
      if (payload.isActive) {
        if (Object.keys(state.activeMission).length === 0) {
          state.activeMission = { ...payload };
        } else {
          state.inactiveMissions.push(state.activeMission);
          state.activeMission = { ...payload };
        }
        state.inactiveMissions.splice(payload.toEditIndex, 1);
      }
    },
  },
});

export default rememberMissionItemsSlice.reducer;
export const {
  setInactiveMissionsItems,
  setActiveMissionItems,
  addInactiveMissionItem,
  editMission,
} = rememberMissionItemsSlice.actions;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig1 = {
  apiKey: 'AIzaSyAhJxc7SDAt_Oj7Ag1VKHCKrqx7kvRGi5w',
  authDomain: 'cazino365-4412a.firebaseapp.com',
  projectId: 'cazino365-4412a',
  storageBucket: 'cazino365-4412a.appspot.com',
  messagingSenderId: '482327326794',
  appId: '1:482327326794:web:d20f228135f6576d1af6b3',
  measurementId: 'G-3F3TGXEP76',
};

// Initialize Firebase
const app1 = initializeApp(firebaseConfig1, 'secondary');
export const auth = getAuth;
export const db = getFirestore(app1);

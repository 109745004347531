import * as yup from 'yup';

const MissionValidationSchema = yup.object().shape({
  description: yup
    .string()
    .required('Descrierea este obligatorie')
    .min(5, 'Descrierea trebuie să aibă cel puțin 5 caractere'),

  endDate: yup
    .date()
    .nullable()
    .required('Data de încheiere este obligatorie')
    .typeError('Data de încheiere trebuie să fie o dată validă'),

  games: yup
    .array()
    .of(yup.string())
    .required('Jocurile sunt obligatorii')
    .min(1, 'Trebuie să existe cel puțin un joc'),

  imageURL: yup
    .string()
    .url('URL-ul imaginii trebuie să fie valid')
    .required('URL-ul imaginii este obligatoriu'),

  isActive: yup.boolean().required('Starea de activitate este obligatorie'),

  name: yup
    .string()
    .required('Numele este obligatoriu')
    .min(3, 'Numele trebuie să aibă cel puțin 3 caractere'),

  prize: yup
    .number()
    .required('Premiul este obligatoriu')
    .min(0, 'Premiul trebuie să fie un număr pozitiv'),
});

export default MissionValidationSchema;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  merchItems: [],
  digitalItems: [],
  paysafelItems: [],
  casinoItems: [],
};

const rememberOrderItemSlice = createSlice({
  name: 'orderItems',
  initialState,
  reducers: {
    setOrderItems: (state, { payload }) => {
      Object.entries(payload).map((entry, index) => {
        state[entry[0]] = payload[entry[0]];
        state[entry[0]] = state[entry[0]].sort(function (a, b) {
          return (
            new Date(a.dateOfOrderPlacement) - new Date(b.dateOfOrderPlacement)
          );
        });
      });
    },
    addOrderItem: (state, { payload }) => {
      if (payload.category === 'merchandise') {
        state.merchItems.push(payload.order);
        state.merchItems = state.merchItems.sort(function (a, b) {
          return (
            new Date(b.dateOfOrderPlacement) - new Date(a.dateOfOrderPlacement)
          );
        });
      } else if (payload.category === 'digitalProducts') {
        state.digitalItems.push(payload.order);
        state.digitalItems = state.digitalItems.sort(function (a, b) {
          return (
            new Date(b.dateOfOrderPlacement) - new Date(a.dateOfOrderPlacement)
          );
        });
      }
    },
    setOrderItemsArrays: (state, { payload }) => {
      console.log(payload);
      for (let element of payload.orders) {
        if (element.category === 'merchandise') {
          state.merchItems.push(element.order);
          state.merchItems = state.merchItems.sort(function (a, b) {
            return (
              new Date(b.dateOfOrderPlacement) -
              new Date(a.dateOfOrderPlacement)
            );
          });
        } else if (element.category === 'digitalProducts') {
          state.digitalItems.push(element.order);
          state.digitalItems = state.digitalItems.sort(function (a, b) {
            return (
              new Date(b.dateOfOrderPlacement) -
              new Date(a.dateOfOrderPlacement)
            );
          });
        }
      }
    },
    setOrderStatus: (state, { payload }) => {
      if (payload.category === 'filteredOrderM') {
        for (let index in state.merchItems) {
          if (
            state.merchItems[index].id === payload.userId &&
            state.merchItems[index].orderId === payload.orderId
          ) {
            state.merchItems[index].orderStatus = payload.orderStatus;
            state.merchItems[index].dateOfLastStatusChange = Date();
          }
        }
      } else if (payload.category === 'filteredOrdersD') {
        for (let index in state.digitalItems) {
          if (
            state.digitalItems[index].id === payload.userId &&
            state.digitalItems[index].orderId === payload.orderId
          ) {
            state.digitalItems[index].orderStatus = payload.orderStatus;
            state.digitalItems[index].dateOfLastStatusChange = Date();
          }
        }
      } else if (payload.category === 'filteredOrdersC') {
        for (let index in state.casinoItems) {
          if (
            state.casinoItems[index].id === payload.userId &&
            state.casinoItems[index].orderId === payload.orderId
          ) {
            state.casinoItems[index].orderStatus = payload.orderStatus;
            state.casinoItems[index].dateOfLastStatusChange = Date();
          }
        }
      } else if (payload.category === 'filteredOrdersP') {
        for (let index in state.paysafelItems) {
          if (
            state.paysafelItems[index].id === payload.userId &&
            state.paysafelItems[index].orderId === payload.orderId
          ) {
            state.paysafelItems[index].orderStatus = payload.orderStatus;
            state.paysafelItems[index].dateOfLastStatusChange = Date();
          }
        }
      }
    },
    removeItemFromOrder: (state, { payload }) => {
      if (payload.category === 'paysafeCards') {
        state.paysafelItems = state.paysafelItems.filter(
          element => element.orderId !== payload.orderId
        );
      } else if (payload.category === 'casinoOffers') {
        state.casinoItems = state.casinoItems.filter(
          element => element.orderId !== payload.orderId
        );
      }
    },
  },
});

export default rememberOrderItemSlice.reducer;
export const {
  setOrderItems,
  removeItemFromOrder,
  addOrderItem,
  setOrderStatus,
  setOrderItemsArrays,
} = rememberOrderItemSlice.actions;

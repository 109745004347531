import { yupToFormErrors } from 'formik';
import * as Yup from 'yup';

const PointsSchema = Yup.object().shape({
  puncteBonus: Yup.number('Campul trebuie sa fie un numar.').required(
    'Campul este obligatoriu.'
  ),
});

export default PointsSchema;

import { db } from './firebase-users-config';

import {
  arrayUnion,
  collection,
  getDocs,
  query,
  updateDoc,
  doc,
  arrayRemove,
  setDoc,
} from 'firebase/firestore';

const casinosCollectionRef = collection(db, 'bonuses');

class CasinosDataService {
  getAllCasinos = () => {
    return getDocs(query(casinosCollectionRef));
  };

  addCazino = (newCasino, id) => {
    console.log(newCasino, id);
    const docRef = doc(db, 'bonuses', id);
    return updateDoc(docRef, {
      cazinos: arrayUnion(newCasino),
    });
  };

  deleteCazino = (toDeleteCasino, id) => {
    console.log(toDeleteCasino, id);
    const docRef = doc(db, 'bonuses', id);
    return updateDoc(docRef, {
      cazinos: arrayRemove(toDeleteCasino),
    });
  };

  editCazino = (toEditCazinoItem, id, index, arrayToUpdate) => {
    let newArray = [];
    let current = 0;
    for (let element of arrayToUpdate) {
      if (current === parseInt(index)) {
        newArray.push(toEditCazinoItem);
      } else {
        newArray.push(element);
      }
      current = current + 1;
    }
    const docRef = doc(db, 'bonuses', id);
    return updateDoc(docRef, {
      cazinos: newArray,
    });
  };
}

export default new CasinosDataService();

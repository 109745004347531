import React from 'react';
import MainteneanceDataService from '../../services/maintenence-services';
import PGGamesDataService from '../../services/Pacanele-Gratis/games-services';
import games_array from '../../services/gamesArray';

import { Button, VStack } from '@chakra-ui/react';

function JibberishWork() {
  const handleAction = () => {
    // MainteneanceDataService.transferCasinoDataForAllUsers();
  };

  const getAllGames = async () => {
    // const gamesJson = await GamesDataService.getAllGames();
    // let tmp = gamesJson.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    // console.log(tmp);
    // let jsonString = `${JSON.stringify(tmp)}`;
    // console.log(jsonString);
    // let link = document.createElement('a');
    // link.href = jsonString;
    // let today = new Date();
    // let time = today.toLocaleDateString('ro-RO');
    // link.download = time + '_jocuri_app.json';
    // link.click();
  };

  const readJson = async () => {
    // for (let x in games_array) {
    //   let category = games_array[x].id;
    //   // if (category === 'EgyptQuest') category = 'egyptQuest';
    //   console.log(category);
    //   console.log(games_array[x].games);
    //   for (let gameId in games_array[x].games) {
    //     console.log(games_array[x].games[gameId]);
    //     await PGGamesDataService.addGameItem(
    //       games_array[x].games[gameId],
    //       category,
    //       gameId
    //     );
    //   }
    // }
  };

  return (
    <>
      <VStack>
        <Button onClick={() => console.log(handleAction)}>
          transferCasinoDataForAllUsers
        </Button>
        <Button onClick={() => getAllGames()}>getAllGames</Button>

        <Button onClick={() => readJson()}>Read Json</Button>
      </VStack>
    </>
  );
}

export default JibberishWork;
